#book-review-forbidden-word {
  & .wttNode {
    text-align: left;
  }
  & .ul-none {
    padding: 0;
    & .wAuto {
      line-height: 0;
      font-size: 14px;
      color: #232323;
      font-weight: 600;
      padding: 0 30px 0 30px;
    }
    & .wAuto:hover {
      background-color: black;
      color: white;
    }
    & .fAddTxt {
      display: block;
      padding-right: 110px;
    }
  }
  & .wfix {
    text-align: left;
  }
}
