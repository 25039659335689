#main {
  & .main-container {
    height: calc(100% - 172px);
    min-height: 797px;
    display: flex;
    width: 100%;
    & .main-menu-container {
      width: calc(100% - 220px);
      padding: 36px 0 200px 0;
      & .main-menu-item {
        float: left;
        width: 25%;
        height: 220px;
        background: #fff;
        transition: all 200ms linear;
        & .menu-link {
          display: block;
          position: relative;
          padding: 54px 0 0 0;
          text-align: center;
          background: url("../../imgs/main_menu_dash.png") left 50% top 32px
            no-repeat;
          & .main-menu-title {
            font-size: 28px;
            color: #000;
            line-height: 1.2;
            font-weight: 600;
            padding: 0 0 4px 0;
            display: inline-block;
          }
          & .main-menu-icon {
            & .menu-icon {
              & div {
                margin: auto;
                & img {
                  vertical-align: top;
                }
              }
            }
          }
          & .main-menu-description {
            display: inline-block;
            font-size: 14px;
            color: #585858;
            line-height: 1.2;
            border-bottom: 1px #585858 solid;
            padding: 25px 0 2px 0;
          }
        }
      }
      & .main-menu-item:nth-child(2) {
        background: #f7f7f7;
      }
      & .main-menu-item:nth-child(4) {
        background: #f7f7f7;
      }
      & .main-menu-item:nth-child(5) {
        background: #f7f7f7;
      }
      & .main-menu-item:nth-child(7) {
        background: #f7f7f7;
      }
      & .main-menu-item:hover {
        background: #ecf6ff;
      }
    }
  }
}
