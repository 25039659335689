#book-search {
  & .book-search-wrapper {
    & .page-content-box {
      & .search-container {
        display: flex;
        width: 100%;
        align-content: center;
        margin-bottom: 10px;
        & .search-data-box {
          display: flex;
          width: 100%;
          & ul {
            list-style: none;
            width: 100%;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            height: 32px;
            & li:first-child {
              width: 300px;
              margin-right: 10px;
              & .search-title {
                margin-right: 25px;
                line-height: 32px;
                font-size: 14px;
                font-weight: 600;
                width: 100%;
              }
            }
            & li:nth-child(2) {
              margin-right: 20px;
              & .search-button-box {
                & .search-btn {
                  width: 75px;
                  font-size: 16px;
                  color: #333;
                  line-height: 20px;
                  height: 28px;
                  border: 1px solid black;
                  border-radius: 20px;
                }
                & .search-btn:hover {
                  background-color: black;
                  color: white;
                  transition: 0.7s;
                }
              }
            }
            & li:nth-child(4) {
              margin-right: 10px;
            }
            & li:last-child {
              display: flex;
              align-items: center;
              & .period {
                display: flex;
                & div {
                  display: flex;
                  align-items: center;
                }
                & .range-text {
                  margin: 0 10px;
                }
                & .MuiFormControl-root {
                  width: 30%;

                  & .MuiInputBase-root {
                    height: 32px;
                    padding-left: 13px;
                    padding-bottom: 2px;
                    // border: 1px solid #3091ff;
                    // border-radius: 10px;
                    & .MuiInputBase-input {
                      position: relative;
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
        }
        & input[readonly] {
          background-color: #fff;
        }
        & .new-bookinfo-btn {
          width: 180px;
          height: 35px;
          border: 1px solid #3091ff;
          border-radius: 20px;
          color: black;
          font-size: 16px;
          text-align: center;
        }
        & .new-bookinfo-btn:hover {
          background-color: #3091ff;
          color: white;
          transition: 0.5s;
        }
      }
      & .content-table-container {
        max-height: 500px;

        & td {
          font-weight: 500;
        }

        & .MuiPaper-root {
          box-shadow: none !important;
          & .MuiTableContainer-root {
            & .MuiBox-root {
              box-shadow: none;
            }
            & table thead tr th.MuiTableCell-root {
              border-top: 2px #00182e solid;
              background-color: #fff;
              border-bottom: 1px solid #888888;
              box-shadow: none;
            }
            & tfoot {
              background-color: #f7f7f7;
            }
          }
          & .MuiInputBase-root {
            // border: 1px solid black;
            // border-radius: 3px;
            // background-color: white;
            // padding-left: 5px;
            // border: 1px solid #3091ff;
            // border-radius: 8px;
          }
        }
      }
      & .faq-contents {
        width: 100%;
        padding: 0 26px;
        margin: 40px 0 0 0;

        & .faq-box {
          position: relative;
          width: 100%;
          min-height: 32px;
          margin: 0 0 10px 0;
          padding: 0 0 0 84px;
          & .faq-title {
            position: absolute;
            left: 0;
            top: 8px;
            & .faq-type {
              font-size: 14px;
              color: #333;
              line-height: 1.2;
            }
          }
          & .first-title {
            top: 0;
          }
          & .faq-data {
            position: relative;
            font-size: 0;
            line-height: 0;

            & .faq-select {
              width: 250px;
              height: 32px;
              float: left;
            }
            & .faq-textfield {
              width: 100%;
            }
            & .faq-text {
              width: 100%;
              height: 120px;
              line-height: 1.4;
              padding: 12px;
              font-size: 12px;
              border: 1px #d2d2d2 solid;
              border-radius: 8px;
              margin: 0;
              box-sizing: border-box;
              vertical-align: middle;
            }
          }
        }
      }
    }
    & .select-options {
      height: 32px;
      width: 250px;
      text-align: left;
      margin-right: 10px;
    }

    & .textfield-options {
      width: 100%;
    }
    & .MuiTableHead-root {
      & .MuiTableRow-root {
        & th:nth-child(1) {
          width: 5px;
        }
      }
    }
    & .MuiTableBody-root {
      & .MuiTableRow-root {
        & td:nth-child(1) {
          width: 5px;
        }
      }
    }
  }
}
