& .recommended-book-a-container {
  background-color: #f5f5f5;
  display: flex;
  margin-top: 80px;
  & .recommended-book-a {
    width: 1400px;
    margin: 80px auto 0;
    display: flex;
    flex-direction: column;
  }
}

& .recommended-book-b {
  margin: 80px auto 0;
  width: 1400px;
  & .swiper-pagination {
    display: none;
  }
  & .next-button,
  & .prev-button {
    margin-top: -240px !important;
  }
}

& .recommended-book-c {
  margin: 80px auto 0;
  & .title-container {
    width: 1400px;
    margin: 0 auto;
  }
  & .swiper-auto {
    margin-top: 24px;
    height: 600px;
    & .swiper-slide {
      margin-top: 8px;
      width: fit-content;
      height: 528px;
      box-shadow: 0px 5px 20px #0000001a;
      border-radius: 16px;
      display: flex;
      padding: 0 100px 0 160px;
      & .book-img {
        width: 260px;
        margin: auto 0;
        cursor: pointer;
        & img {
          width: 260px;
          height: 400px;
          box-shadow: 0px 5px 10px #00000029;
          border-radius: 8px;
        }
      }
      & #book-info-container {
        margin-top: 100px;
        margin-left: 140px;
        & button {
          width: 113px;
          height: 22px;
        }
      }
    }
    & .swiper-pagination {
      & .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        &.swiper-pagination-bullet-active {
          width: 20px;
          border-radius: 10px;
          background-color: black;
        }
      }
    }
  }
}

& .recommended-book-d {
  margin: 80px auto 0;
  width: 1400px;
  & .curation-container {
    margin-top: 32px;
    display: flex;
    & .curation-card {
      padding: 30px;
      width: 450px;
      height: 605px;
      margin-right: 25px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 16px;
      cursor: pointer;
      & .book-card {
        width: 390px;
        height: 390px;
        border-radius: 12px;
        background-color: white;
        display: flex;
        flex-direction: column;
        & .number-tag {
          width: 32px;
          height: 48px;
          background-color: #b7916b;
          color: white;
          font-weight: bold;
          padding: 12px 11px;
          border-radius: 0 0 4px 4px;
          margin-left: 16px;
        }
        & .book-img {
          width: 160px;
          margin: 0 auto;
          & img {
            width: 160px;
            height: 240px;
            box-shadow: 0px 5px 10px #00000029;
            border-radius: 8px;
          }
        }
        & .title {
          margin-top: 24px;
          font-weight: bold;
          font-size: 16px;
        }
        & .info-container {
          margin: 8px auto;
          display: flex;
          width: fit-content;
          color: #777777;
          font-size: 12px;
          & div {
            height: 8px;
            width: 1px;
            border-left: 1px solid #777777;
            margin: auto 8px;
          }
        }
      }
      & .comment-title {
        margin-top: 30px;
        color: #b7916b;
        font-size: 13px;
      }
      & .comment {
        width: 338px;
        margin: 20px auto 0;
        font-size: 14px;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
  }
}
