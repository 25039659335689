#modoo-order-detail {
  & .vttNode {
    text-align: left;
  }
  & .vWrap {
    & .vtt,
    & .vtx,
    & .dtt,
    & .dtx {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      li {
        list-style: none;
      }
    }
  }
  & .tit {
    text-align: left;
  }

  & .dtx {
    min-height: 14.39px;
  }

  & button {
    &.whiteBtn.wAuto {
      width: auto;
      height: 30px;
      padding: 0 32px;
      border-radius: 50px;
      border: 1px #232323 solid;
      background: #fff;
      text-align: center;
      font-size: 14px;
      color: #232323;
      font-weight: 600;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    &.blackBtn.wAuto {
      width: auto;
      height: 30px;
      padding: 0 32px;
      border-radius: 50px;
      border: 1px #232323 solid;
      background: #232323;
      text-align: center;
      font-size: 14px;
      color: #fff;
      font-weight: 600;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }

  & .btnArea {
    margin-top: 15px;
    padding: 0;
    & .rDiv {
      height: 30px;
    }
  }

  & .tbList {
    &.rcpt-list {
      & .MuiTableCell-root {
        &:first-of-type {
          display: none;
        }
      }
    }
    &.order-list {
      & .MuiTableCell-root {
        &:first-of-type {
          max-width: 60px !important;
          justify-content: center;
        }
      }
    }
    & .MuiPaper-root {
      box-shadow: none;
      & .Mui-TableHeadCell-Content-Wrapper {
        font-size: 12px;
      }
      & .MuiTableCell-body {
        font-size: 14px;
        font-weight: 500;
      }
      & .MuiTableFooter-root {
        & .MuiTableCell-root {
          padding: 0 5px 0 10px;
          font-size: 14px;
          font-weight: 500;
          line-height: 70px;
          &:nth-child(8) {
            font-size: 18px;
          }
        }
      }
    }
  }

  & .baseBx {
    & .textarea {
      resize: none;
    }
  }

  & .return-list-btn-area {
    &:last-of-type {
      margin-top: 30px;
    }
    & .rDiv {
      display: flex;
      justify-content: end;
    }
  }
}
