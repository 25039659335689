#mall-member-detail {
  & .body-container {
    & .content-wrapper {
      & .modoo-member-detail-wrapper {
        & .page-contents {
          & .roundBx {
            & .tbList {
              & table {
                & tr:hover {
                  background-color: unset;
                }
                & tr {
                  & td:hover {
                    background-color: rgba(235, 235, 235);
                  }
                }
              }
            }
            & .mallCpUser {
              & .MuiPaper-root {
                & .MuiTableContainer-root {
                  & .MuiTable-root {
                    & .MuiTableHead-root {
                      & .MuiTableRow-root {
                        & .MuiTableCell-root:nth-of-type(1) {
                          display: none;
                        }
                      }
                    }
                    & .MuiTableBody-root {
                      & .MuiTableRow-root {
                        & .MuiTableCell-root:nth-of-type(1) {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
            & .mallOrd30Day {
              & .MuiPaper-root {
                & .MuiTableContainer-root {
                  & .MuiTable-root {
                    & .MuiTableHead-root {
                      & .MuiTableRow-root {
                        & .MuiTableCell-root:nth-of-type(1) {
                          display: none;
                        }
                      }
                    }
                    & .MuiTableBody-root {
                      & .MuiTableRow-root {
                        & .MuiTableCell-root:nth-of-type(1) {
                          display: none;
                        }
                        & .MuiTableCell-root {
                          & .MuiInputBase-root {
                            width: 100%;
                            & input {
                              width: 100%;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
