// @import url(//fonts.googleapis.com/earlyaccess/notosansmyanmar.css);
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
$notoSansKr: "Noto Sans KR", sans-serif;
// $notoSansMyanmar: 'Noto Sans Myanmar';

/*
1. IE 낮은 버전을 위해서 iefix 작업이 필요한가?
2. otf를 사용할 것인가 ttf를 사용할 것 인가?
3. local 키워드를 사용하면 다운로드 시간이 체감되지 않는가? 
*/

// 기본적으로 웹폰트에서 직접 다운로드하는 방식으로 변경 하였으므로, 폰트들의 용량 크기를 줄이기 위해 의미 없는 글자 '갋' 같은 것을 제거한 subset 파일을 사용해야함
// 참고 자료
// https://wit.nts-corp.com/2017/02/13/4258
// https://webdir.tistory.com/56

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-display: fallback;
  font-weight: 100;
  src: local("NotoSansKR-Thin") url(../fonts/NotoSansKR-Thin.woff2)
      format("woff2"),
    url(../fonts/NotoSansKR-Thin.woff) format("woff"),
    url(../fonts/NotoSansKR-Thin.otf) format("otf");
  //src: local(※), url(../fonts/NotoSansKR-Thin.woff) format('woff');
  // local(※) 값은 외부 자원을 참조하기 이전에 시스템에 설치된 글꼴을 우선 참조할 수 있도록 만들어 준다. 그러나 이코드에서는 IE6~IE8이 local() 값을 처리하지 못하는 특징을 이용하여 woff 글꼴을 추가로 요청하지 않도록 해준다.
  // local(※) 값의 괄호 안쪽에 포함된 ※ 기호는 사용자 시스템에 존재하지 않을만한 글꼴을 임의로 지정한 것. 굳이 2byte짜리 특수문자를 사용한 이유는 Mac OS에서 2byte짜리 문자열로 된 시스템 글꼴 이름은 아예 처리하지 않기 때문이다.
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: local("NotoSansKR-Light"),
    url(../fonts/NotoSansKR-Light.woff2) format("woff2"),
    url(../fonts/NotoSansKR-Light.woff) format("woff"),
    url(../fonts/NotoSansKR-Light.otf) format("otf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: local("NotoSansKR-Regular"),
    url(../fonts/NotoSansKR-Regular.woff2) format("woff2"),
    url(../fonts/NotoSansKR-Regular.woff) format("woff"),
    url(../fonts/NotoSansKR-Regular.otf) format("otf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: local("NotoSansKR-Medium"),
    url(../fonts/NotoSansKR-Medium.woff2) format("woff2"),
    url(../fonts/NotoSansKR-Medium.woff) format("woff"),
    url(../fonts/NotoSansKR-Medium.otf) format("otf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: local("NotoSansKR-Bold"),
    url(../fonts/NotoSansKR-Bold.woff2) format("woff2"),
    url(../fonts/NotoSansKR-Bold.woff) format("woff"),
    url(../fonts/NotoSansKR-Bold.otf) format("otf");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: local("NotoSansKR-Black"),
    url(../fonts/NotoSansKR-Black.woff2) format("woff2"),
    url(../fonts/NotoSansKR-Black.woff) format("woff"),
    url(../fonts/NotoSansKR-Black.otf) format("otf");
}

// @font-face {
//     font-family: NotoSansCJKkr-Light;
//     font-style: normal;
//     font-weight: 300;
//     src: local('NotoSansKR-Light'), local('NotoSansCJKkr-Light'),
//         url(../fonts/NotoSansKR-Light.woff2) format('woff2'),
//         url(../fonts/NotoSansKR-Light.woff) format('woff'),
//         url(../fonts/NotoSansKR-Light.otf) format('otf');
// }
