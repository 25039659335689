#modoo-event-register {
  & .body-container {
    & .content-wrapper {
      & .modoo-event-wrapper {
        & .page-contents {
          & .roundBx {
            & .registList {
              & .reBx {
                & .ab {
                  top: 0px;
                }
                & .bannerImg {
                  top: 35px;
                }
                &.interval,
                &.bigInterval {
                  // 문자 수 표기
                  & .length {
                    font-size: 12px;
                    color: #333;
                    line-height: 32px;
                    text-align: right;
                  }
                }
                & .data {
                  // 기획전 제목
                  &.title {
                    width: 320px;
                  }
                  & .period {
                    float: left;
                    min-width: 340px;
                    & .date-picker {
                      float: left;
                      margin-right: 20px;
                      & .MuiInputBase-root {
                        max-height: 32px;
                        max-width: 150px;
                      }
                    }
                    & .range-text {
                      position: relative;
                      right: 95px;
                      top: 16px;
                      font-size: large;
                    }
                  }
                  & .iList {
                    & ul {
                      padding: 0;
                      & li {
                        & .addTxt {
                          padding-top: 3px;
                          text-align: left;
                        }
                      }
                    }
                  }
                  & .templates {
                    & ul {
                      padding: 0;
                      & li {
                        padding: 0;
                        & .fmRad-i {
                          & .img {
                            & div {
                              margin: 0 auto;
                              width: 117px;
                              cursor: pointer;
                            }
                          }
                        }
                        & .fmRad-i::before {
                          display: none;
                        }
                        & .fmRad-i > .fmRad:checked ~ .img > div {
                          border: 1px solid #ff0000;
                        }
                      }
                    }
                  }
                  & textarea {
                    resize: vertical;
                  }
                  & .bSearch {
                    text-align: left;
                  }
                  & .httNode {
                    display: flex;
                    justify-content: space-between !important;
                  }
                  & .selectList {
                    & .seLx {
                      & .seDx {
                        & div {
                          & .seBx {
                            & .st {
                              top: 15px;
                            }
                            & .data {
                              & p {
                                text-align: left;
                              }
                            }
                          }
                        }
                        & .item1 {
                          width: 45%;
                        }
                        & .item2 {
                          width: 20%;
                        }
                        & .item3 {
                          width: 15%;
                        }
                        & .item4 {
                          width: 20%;
                        }
                      }
                      & .delBtn {
                        top: 35px;
                      }
                    }
                  }
                }
              }
              // 선택한 항목 수 표기
              & .selected-item-count {
                text-align: right;
                font-size: 14px;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
