#modoo-mall-management {
  & .modoo-mall-management-wrapper {
    // min-width: 1650px;
    & .content-header-container {
      & .search-filter-container {
        display: flex;
        height: 32px;
        margin-bottom: 30px;
        & .search-filter {
          display: flex;
          margin-right: 30px;
          & .search-filter-title {
            margin-right: 15px;
            line-height: 32px;
            font-size: 14px;
            font-weight: 600;
          }
          & .search-filter-component {
            & .input-field {
              height: 32px;
              width: 250px;
              & input {
                height: 32px;
                padding-top: 0px;
                padding-bottom: 0px;
              }
            }
          }
        }
        & .search-filter-btn {
          display: flex;
          width: 100%;
          & .searchBtn {
            width: 12%;
            justify-items: flex-start;
            border: 2px solid #1e76af;
            border-radius: 8px;
            font-weight: 500;
          }
          & .searchBtn:hover {
            background-color: #1e76af;
            color: white;
          }
        }
      }
    }

    & .content-table-container {
      max-height: 500px;
      .MuiPaper-root {
        box-shadow: none !important;
        & .MuiTableContainer-root {
          & .MuiBox-root {
            box-shadow: none;
            height: 100%;
            display: flex;
            align-items: center;
          }
          & table thead tr th.MuiTableCell-root {
            border-top: 2px #00182e solid;
            border-bottom: 1px solid #707070;
            background: #f9f9f9;
          }
          & td {
            font-weight: 500;
          }
          & tfoot {
            background-color: #f7f7f7;
          }
        }
        & .MuiInputBase-root {
          border: 1px solid black;
          border-radius: 3px;
          background-color: white;
          padding-left: 5px;
        }
      }
    }
  }
}
