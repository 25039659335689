#pub-manager-info {
  & .pub-manager-info-wrapper {
    width: 100%;

    & .btn-container {
      display: flex;
      justify-content: right;
    }

    & .add-btn-box {
      padding: 0 0 10px 0;
    }

    & .add-row-btn {
      width: 105px;
      height: 35px;
      border-radius: 20px;
      color: black;
      font-size: 15px;
    }

    & .add-row-btn:hover {
      background-color: dodgerblue;
      border: 1px solid dodgerblue;
      color: white;
      transition: 0.5s;
    }

    & .bottom-box {
      display: flex;
      justify-content: space-between;
      padding-top: 15px;

      & .notice-sentence {
        font-size: 12px;
        color: #ff0000;
        line-height: 1.2;
        padding: 10px 0 0 0;
      }

      & .insert-btn {
        width: 84px;
        border-radius: 20px;
        height: 30px;
        background-color: black;
        color: white;
      }
    }

    & .content-table-container {
      max-height: 500px;

      .MuiPaper-root {
        box-shadow: none !important;
        & .MuiTableContainer-root {
          & .MuiBox-root {
            box-shadow: none;
          }
          & table thead tr th.MuiTableCell-root {
            border-top: 2px #00182e solid;
            background-color: #f7f7f7;
            border-bottom: 1px solid #e6e6e6;
          }
          & tfoot {
            background-color: #f7f7f7;
          }
        }
        & .MuiInputBase-root {
          border: 1px solid black;
          border-radius: 3px;
          background-color: white;
          padding-left: 5px;
        }
      }
    }
  }
}
