#store-one-to-one {
  & .store-one-to-one-wrapper {
    width: 100%;
    min-width: 880px;
    & .top-search {
      width: 100%;
      display: flex;
      height: 35px;
      margin-bottom: 10px;
    }
    & .f-div {
      display: flex;
      width: 100%;
      & .search-filter-div {
        display: flex;
        line-height: 35px;
        margin-right: 30px;
        & .search-filter-title {
          margin-right: 15px;
          line-height: 35px;
          font-size: 14px;
          font-weight: 600;
        }
        & .search-filter-component {
          display: flex;
          height: 35px;

          & .date-picker.range {
            display: flex;
            & .date-picker {
              width: 150px;
            }
            & .range-text {
              width: 25px;
              line-height: 35px;
            }
          }
          & .MuiInputBase-root {
            max-width: 160px;
            min-width: 50px;
            height: 35px;
          }
          & .MuiSelect-select {
            max-width: 250px;
            min-width: 100px;
          }
        }
      }
      & .btn-div {
        width: 20%;
        display: flex;
        justify-content: flex-start;
        padding-left: 15px;
        & .searchBtn {
          background-color: black;
          color: white;
          border-radius: 15px;
          min-width: 80px;
          height: 35px;
        }
      }
    }
    & .allSBtn {
      padding: 10px 0 20px;
      margin-bottom: 5px;
    }

    & .content-table-container {
      border-top: 2px #000 solid;
    }
  }
  // 답변 css
  & .request-container {
    & .request-content-container {
      display: flex;
      margin-top: 30px;
      & .request-content-left {
        min-width: 789px;
        width: 50%;
        padding-right: 10px;
        & .answer-input {
          width: 100%;
          background-color: #eee;
          border: 1px #d2d2d2 solid;
          border-radius: 8px;
          & pre {
            margin: 0;
            padding: 6px 12px;
            text-align: left;
            font-size: 14px;
            font-family: "Noto Sans KR", "sans-serifs";
          }
        }
      }
      & .request-content-right {
        min-width: 789px;
        width: 50%;
        padding-left: 10px;
        border: 1px;
      }
    }
    & .request-container-span {
      margin-right: 10px;
      padding-top: 2px;
      min-width: 60px;
      text-align: left;
    }
    & input {
      min-width: 250px;
    }
    & .request-menu-top {
      display: flex;
      width: 100%;
      & .request-menu {
        display: flex;
        margin-right: 20px;
      }
    }
    & .request-title {
      display: flex;
      margin-top: 15px;
    }
    & .request-content {
      display: flex;
      margin-top: 15px;
    }
    & .request-textarea {
      display: flex;
      & textarea {
        min-height: 100px;
        resize: none;
      }
    }
    & .regist {
      text-align: center;
      margin: 0;
    }
    & .reset {
      text-align: center;
    }
    & .whiteBtn {
      & span {
        margin: 0;
      }
    }
  }
}
