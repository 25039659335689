& .channel-container {
  display: flex;
  flex-direction: column;
  margin: 80px auto 0;
  width: 1400px;
  & .video {
    margin-top: 32px;
    height: 786px;
    & img {
      width: 1400px;
      height: 786px;
      border-radius: 16px 16px 0px 0px;
    }
  }
  & .description {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 16px 16px;
    height: 83px;
    padding: 24px;
    display: flex;
    & .play-ico {
      display: flex;
      margin-left: 8px;
      margin-right: 24px;
      & div {
        margin: auto;
      }
    }
    & .title {
      font-size: 24px;
      font-weight: bold;
    }
    & .more {
      background-color: #f5f5f5;
      margin-left: auto;
    }
  }
}
