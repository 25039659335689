#modoo-one-to-one {
  & .modoo-one-to-one-wrapper {
    width: 100%;
    min-width: 880px;
    & .top-search {
      width: 100%;
      display: flex;
    }
    & .f-div {
      width: 100%;
      display: flex;
      padding-bottom: 20px;
      & .search-filter-component {
        display: flex;
        height: 32px;
        font-size: 14px;
        color: #333;
        line-height: 29px;
        font-weight: 600;
        padding-right: 10px;
        & .date-picker.range {
          display: flex;
          & .date-picker {
            width: 100%;
          }
          & .range-text {
            width: 25px;
            padding-right: 5px;
            line-height: 32px;
          }
        }
        & .MuiInputBase-root {
          max-width: 160px;
          min-width: 50px;
          height: 32px;
        }
        & .MuiSelect-select {
          max-width: 250px;
          min-width: 100px;
        }
      }
    }
    & .b-div {
      & .search-filter-component {
        width: 100%;
        display: flex;
        height: 32px;
        font-size: 14px;
        color: #333;
        line-height: 29px;
        font-weight: 600;
        padding-right: 10px;
        & .title-box {
          & span {
            margin-right: 10px;
          }
        }
        & .radio-box {
          width: 19%;
        }
        & .auto-input-box {
          width: 12%;
          & .MuiInputBase-root {
            height: 32px;
            padding: 0;
            & input {
              padding: 0 10px;
              font-size: 14px;
              background-color: #fff;
            }
          }
        }
        & .MuiFormGroup-root {
          height: 32px;
          & .MuiFormControlLabel-root {
            height: 32px;
          }
        }
      }
      & .MuiInputBase-root {
        & .MuiOutlinedInput-root {
          width: 250px;
          height: 32px;
        }
      }
      & .bsBox {
        min-width: 260px;
        & .MuiFormControl-root {
          min-width: 260px;
          width: 100%;
          min-height: 32px;
        }
      }
    }
    & .allSBtn {
      padding: 20px 0 20px 0;
      margin-bottom: 5px;
    }
    & .swBtn:hover {
      background-color: black;
      color: white;
    }
    & .reBox {
      display: flex;
      width: 100%;
      margin-bottom: 10px;
      & .abDiv {
        width: 100%;
        display: flex;
        & .data {
          display: flex;
          width: 100%;
        }
      }
      & .flex-component {
        width: 100%;
        display: flex;
        padding-right: 30px;
        & .reStt {
          min-width: 59px;
          height: 32px;
          margin-right: 20px;
          padding-top: 3px;
          text-align: left;
        }
        & .textarea {
          width: 100%;
          //min-width: 300px;
          min-height: 130px;
          resize: none;
        }
        & .sttComponent {
          min-width: 100px;
        }
        & .inquiryTxt {
          min-width: 1420px;
          min-height: 130px;
        }
      }
    }
  }
  // 답변 css
  & .request-container {
    & .request-content-container {
      display: flex;
      margin-top: 30px;
      & .request-content-left {
        width: 55%;
        padding-right: 10px;
        & .answer-input {
          width: 100%;
          background-color: #eee;
          border: 1px #d2d2d2 solid;
          border-radius: 8px;
          & pre {
            margin: 0;
            padding: 6px 12px;
            text-align: left;
            font-size: 14px;
            font-family: "Noto Sans KR", "sans-serifs";
          }
        }
      }
      & .request-content-right {
        width: 45%;
        padding-left: 10px;
        border: 1px;
      }
    }
    & .request-container-span {
      margin-right: 5px;
      min-width: 60px;
      text-align: left;
    }
    & input {
      min-width: 200px;
    }
    // padding-left: 10px;
    & .request-menu-top {
      display: flex;
      // margin-top: 30px;
      width: 100%;
      & .request-menu {
        display: flex;
        margin-right: 20px;
      }
    }
    & .request-menu-bottom {
      display: flex;
      margin-top: 15px;
      width: 100%;
      & .request-menu1 {
        display: flex;
        margin-right: 20px;
      }
    }
    & .request-title {
      display: flex;
      margin-top: 15px;
    }
    & .request-content {
      display: flex;
      margin-top: 15px;
    }
    & .request-textarea {
      display: flex;
      // margin-top: 15px;
      & textarea {
        min-height: 100px;
        resize: none;
      }
    }
    & .regist {
      text-align: center;
      margin: 0;
    }
    & .reset {
      text-align: center;
    }
    & .whiteBtn {
      & span {
        margin: 0;
      }
    }
  }
}
