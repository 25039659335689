/* content inner */
.layerInBx {
  position: relative;
  width: auto;
  margin: 0 20px;
}
.layerInBx:after {
  content: "";
  display: block;
  clear: both;
}
* {
  word-break: keep-all;
}

/* display none */
.disnone {
  display: none;
}

/* content form */
.wttNode {
  position: relative;
  font-size: 20px;
  color: #000;
  line-height: 1.2;
  padding: 0 0 10px 0;
  text-align: left;
}
.wttNode .pview {
  width: 21px;
  height: 18px;
  background: url("../imgs/preview_icon.png") left top no-repeat;
  margin: -3px 0 0 6px;
}
.wttNode * {
  vertical-align: middle;
}
.wttNode span {
  display: inline-block;
  position: relative;
  padding: 0 0 0 18px;
  margin: 0 0 0 12px;
}
.wttNode span:after {
  content: "|";
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 17px;
  color: #000;
  line-height: 1.2;
}
.wttNode .essTxt {
  display: inline-block;
  font-size: 13px;
  color: #ff0000;
  padding: 0 0 0 12px;
}

.httNode {
  position: relative;
  font-size: 18px;
  color: #000;
  line-height: 1.2;
  padding: 0 0 8px 0;
}
.httNode .pview {
  width: 21px;
  height: 18px;
  background: url("../imgs/preview_icon.png") left top no-repeat;
  margin: -3px 0 0 6px;
}
.httNode * {
  vertical-align: middle;
}
.httNode.divide {
  margin: 45px 0 0 0;
  padding: 54px 0 14px 0;
  border-top: 1px #000 solid;
}
.httNode.Interval {
  padding: 45px 0 14px 0;
}
.httNode.dInterval {
  margin: 0 0 30px 0;
}
.httNode .switch {
  margin: -2px 0 0 15px;
}
.httNode .newAppBtn {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.httNode .whiteBtn {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.httNode .tx {
  font-size: 13px;
}

.vttNode {
  position: relative;
  font-size: 18px;
  color: #333;
  line-height: 1.2;
  margin: 40px 0 10px 0;
}
.vttNode.f {
  margin: 0 0 10px 0;
}

/* round box */
.roundBx {
  padding: 30px;
  margin: 0 0 35px 0;
  border: 1px #d2d2d2 solid;
  border-radius: 15px;
}
.roundBx:after {
  content: "";
  display: block;
  clear: both;
}
.roundBx.all {
  padding: 40px;
}
.roundBx.last {
  margin: 0;
}

/* top search */
.topSearch {
  position: relative;
  width: 100%;
  padding: 0 0 10px 0;
}
.topSearch:after {
  content: "";
  display: block;
  clear: both;
}
.topSearch .lDiv {
  float: left;
}
.topSearch .lDiv button {
  margin: 5px 0 0 0;
}
.topSearch .lDiv .htt {
  margin-top: 12px;
  margin-bottom: 0;
}
.topSearch .lDiv > ul {
  font-size: 0;
  line-height: 0;
}
.topSearch .lDiv > ul:after {
  content: "";
  display: block;
  clear: both;
}
.topSearch .lDiv > ul > li {
  float: left;
  font-size: 0;
  line-height: 0;
  margin: 0 10px 0 0;
}
.topSearch .lDiv > ul > li .tt {
  display: inline-block;
  font-size: 14px;
  color: #333;
  line-height: 29px;
  font-weight: 600;
  padding: 0 15px 0 0;
}
.topSearch .lDiv > ul > li .tt u {
  font-size: 14px;
  color: #ff0000;
  line-height: 1.2;
  text-decoration: none;
}
.topSearch .lDiv > ul > li .tt.wfix {
  width: 110px;
}
.topSearch .lDiv > ul > li .tt.wfixmini {
  width: 80px;
}
.topSearch .lDiv > ul > li .whiteBtn {
  height: 32px;
  margin: 0;
}
.topSearch .lDiv > ul > li .whiteBtn span {
  line-height: 32px;
}
.topSearch .lDiv > ul > li.full {
  float: none;
  padding: 10px 0 0 0;
}
.topSearch .lDiv * {
  vertical-align: middle;
}

.topSearch .rDiv {
  float: right;
}
.topSearch .rDiv button {
  margin: 0 0 0 8px;
}
.topSearch .rDiv > ul {
  font-size: 0;
  line-height: 0;
}
.topSearch .rDiv > ul:after {
  content: "";
  display: block;
  clear: both;
}
.topSearch .rDiv > ul > li {
  float: left;
  font-size: 0;
  line-height: 0;
  margin: 0 0 0 10px;
}
.topSearch .rDiv > ul > li button {
  margin: 5px 0 0 0;
}
.topSearch .rDiv > ul * {
  vertical-align: middle;
}
.topSearch .rDiv.mno {
  margin: 0;
}
.topSearch .rDiv.btnType {
  float: none;
  position: absolute;
  right: 0;
  top: 0;
}
.topSearch .rDiv .newAppBtn {
  margin-top: -3px;
}

.topSearch .fDiv {
  width: 100%;
  margin: 10px 0 0 0;
  overflow: hidden;
}
.topSearch .fDiv:first-child {
  margin: 0;
}
.topSearch .fDiv * {
  vertical-align: middle;
}
.topSearch .fDiv ul {
  font-size: 0;
  line-height: 0;
}
.topSearch .fDiv ul:after {
  content: "";
  display: block;
  clear: both;
}
.topSearch .fDiv > ul > li {
  float: left;
  font-size: 0;
  line-height: 0;
  margin: 0 30px 0 0;
}
.topSearch .fDiv > ul > li.full {
  float: none;
  display: block;
  margin: 0;
}
.topSearch .fDiv > ul > li.mno {
  margin: 0 10px 0 0;
}
.topSearch .fDiv > ul > li .tt {
  display: inline-block;
  font-size: 14px;
  color: #333;
  line-height: 29px;
  font-weight: 600;
  padding: 0 15px 0 0;
}
.topSearch .fDiv > ul > li .tt.wfix {
  width: 110px;
}
.topSearch .fDiv > ul > li .tt.wfixmini {
  width: 80px;
}
.topSearch .fDiv > ul > li:last-child {
  margin: 0;
}
.topSearch .fDiv > ul > li > ul li {
  float: left;
  margin: 0 5px 0 0;
}
.topSearch .fDiv strong {
  font-size: 15px;
  color: #333;
  font-weight: 600;
  line-height: 32px;
}
.topSearch .fDiv .swBtn {
  margin: 5px 0 0 0;
}

.topSearch .bsBox {
  display: inline-block;
  position: relative;
  width: 260px;
  height: 32px;
  padding: 0 50px 0 0;
  border: 1px #d2d2d2 solid;
  border-radius: 50px;
  overflow: hidden;
}
.topSearch .bsBox input {
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  border: 0;
  padding: 0 0 0 20px;
  letter-spacing: 0;
}
.topSearch .bsBox button {
  position: absolute;
  right: 10px;
  top: -1px;
  width: 32px;
  height: 32px;
  margin: 0;
  background: url("../imgs/btn_search_magnifier.png") left top no-repeat;
  background-size: 32px;
}

.topSearch .period {
  display: inline-block;
  width: 280px;
}
.topSearch .period:after {
  content: "";
  display: block;
  clear: both;
}
.topSearch .period ul li {
  float: left;
}
.topSearch .period ul li:nth-child(1) {
  width: 46%;
}
.topSearch .period ul li:nth-child(2) {
  width: 8%;
  text-align: center;
  font-size: 12px;
  color: #666;
  line-height: 32px;
}
.topSearch .period ul li:nth-child(3) {
  width: 46%;
}
.topSearch .period.wAuto {
  width: auto;
}

.topSearch .classification {
  display: inline-block;
  padding: 0 0 0 10px;
}
.topSearch .classification:after {
  content: "";
  display: block;
  clear: both;
}
.topSearch .classification ul li {
  float: left;
  margin: 0 28px 0 0;
}
.topSearch .classification ul li label {
  line-height: 32px;
  margin: 0 10px 0 0;
}
.topSearch .classification ul li .fmRad-i:before {
  top: 8px;
}
.topSearch .classification ul li .fmRad-i:after {
  top: 12px;
}
.topSearch .classification ul li .fmRad-i.exception {
  color: #2189ff;
}
.topSearch .classification ul li .fmChk-i:before {
  top: 8px;
}
.topSearch .classification ul li .fmChk-i:after {
  top: 12px;
}
.topSearch .classification ul li .fmChk-i.exception {
  color: #2189ff;
}
.topSearch .classification.appReg {
  padding: 0 0 10px 25px;
}

.topSearch .allSBtn {
  width: 100%;
  text-align: center;
  padding: 20px 0 45px 0;
  margin: 0 0 50px 0;
  border-bottom: 1px #d2d2d2 solid;
  overflow: hidden;
}
.topSearch .allSBtn button {
  width: 92px;
  height: 35px;
  background: #000;
  text-align: center;
  border-radius: 50px;
}
.topSearch .allSBtn button span {
  font-size: 16px;
  color: #fff;
  line-height: 35px;
}

.topSearch.interval {
  margin-top: 40px;
}

.topSearch .fileBx {
  position: relative;
  display: inline-block;
  width: 366px;
}
.topSearch .fileBx:after {
  content: "";
  display: block;
  clear: both;
  *zoom: 1;
}
.topSearch .fileBx input[type="text"] {
  cursor: pointer;
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  border: 1px #d2d2d2 solid;
  border-radius: 8px;
  padding: 0 0 0 12px;
}
.topSearch .fileBx .hideCon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 20;
}
.topSearch .fileBx .hideCon:after {
  content: "";
  display: block;
  clear: both;
}
.topSearch .fileBx .fileHidden {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 32px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  cursor: pointer;
  background: #f3f5f6;
}

.wWon {
  display: inline-block;
  position: relative;
}
.wWon input {
  text-align: right;
  padding: 0 32px 0 0 !important;
}
/* .wWon:after {content:''; position:absolute; right:10px; top:0; font-size:14px; color:#333; line-height:32px; z-index:2;} */

/* book search */
.bSearch {
  width: 100%;
  padding: 22px 0 20px 0;
  overflow: hidden;
}
.bSearch * {
  vertical-align: middle;
}
.bSearch .tit {
  display: inline-block;
  font-size: 18px;
  color: #333;
  line-height: 1.2;
  padding: 0 12px 0 0;
}
.bSearch .bsBox {
  display: inline-block;
  position: relative;
  width: 400px;
  height: 35px;
  padding: 0 50px 0 0;
  border: 1px #000 solid;
  border-radius: 50px;
  overflow: hidden;
}
.bSearch .bsBox input {
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  border: 0;
  padding: 0 0 0 20px;
  letter-spacing: 0;
}
.bSearch .bsBox button {
  position: absolute;
  right: 12px;
  top: -2px;
  width: 35px;
  height: 35px;
  background: url("../imgs/btn_search_magnifier.png") left top no-repeat;
}

/* banner upload */
.bannerNode {
  position: relative;
  width: 100%;
  padding: 20px 130px 20px 125px;
  margin: 20px 0 0 0;
  border-bottom: 1px #333 dotted;
  border-top: 1px #333 solid;
  overflow: hidden;
}
.bannerNode * {
  vertical-align: middle;
}
.bannerNode .tit {
  position: absolute;
  left: 0;
  top: 28px;
  display: inline-block;
  font-size: 18px;
  color: #333;
  line-height: 1.2;
  padding: 0 12px 0 0;
}
.bannerNode .tit button {
  width: 21px;
  height: 18px;
  background: url("../imgs/preview_icon.png") left top no-repeat;
  margin: -3px 0 0 6px;
}
.bannerNode .fileBx {
  position: relative;
}
.bannerNode .fileBx:after {
  content: "";
  display: block;
  clear: both;
  *zoom: 1;
}
.bannerNode .fileBx input[type="text"] {
  cursor: pointer;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  border: 1px #000 solid;
  border-radius: 50px;
  padding: 0 0 0 20px;
}
.bannerNode .fileBx .hideCon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 20;
}
.bannerNode .fileBx .hideCon:after {
  content: "";
  display: block;
  clear: both;
}
.bannerNode .fileBx .fileHidden {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 40px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  cursor: pointer;
  background: #f3f5f6;
}
.bannerNode .uploadBtn {
  position: absolute;
  right: 0;
  top: 20px;
}

/* district */
.districtArea {
  width: 100%;
  border-top: 1px #333 solid;
}
.districtArea:after {
  content: "";
  display: block;
  clear: both;
  *zoom: 1;
}
.districtArea .bSearch {
  padding-top: 0;
}
.districtArea .btnCArea {
  padding-bottom: 40px;
}
.districtArea.last .btnCArea {
  padding-bottom: 20px;
}
.districtNode {
  position: relative;
  width: 100%;
  padding: 14px 0 14px 100px;
  overflow: hidden;
}
.districtNode * {
  vertical-align: middle;
}
.districtNode .tit {
  position: absolute;
  left: 0;
  top: 18px;
  display: inline-block;
  font-size: 18px;
  color: #333;
  line-height: 1.2;
  padding: 0 12px 0 0;
}
.districtNode .pview {
  width: 21px;
  height: 18px;
  background: url("../imgs/preview_icon.png") left top no-repeat;
  margin: 0 0 0 6px;
}

/* table list style */
.tbList {
  text-align: center;
  vertical-align: middle;
  border-top: 2px #000 solid;
}
.tbList:after {
  content: "";
  display: block;
  clear: both;
}
.tbList table {
  width: 100%;
  border-collapse: collapse;
}
.tbList table tr:hover {
  background-color: rgba(235, 235, 235);
}
.tbList th {
  height: 34px;
  border-bottom: 1px solid #707070;
  background: #f9f9f9;
}
.tbList th span {
  display: inline-block;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.2;
  font-weight: 500;
  padding: 2px 0 0 0;
}
.tbList td {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.2;
  border-bottom: 1px #ebebeb solid;
  padding: 11px 5px 10px;
}
.tbList td strong {
  color: #222;
}

.tbList tr.bg1 td {
  background: #fff4f9;
}
.tbList tr.bg2 td {
  background: #effafa;
}
.tbList tr.bg3 td {
  background: #f9f9f9;
}

.tbList th.bg1 {
  background: #fff4f9 !important;
}
.tbList td.bg1 {
  background: #fff4f9 !important;
}
.tbList th.bg2 {
  background: #effafa !important;
}
.tbList td.bg2 {
  background: #effafa !important;
}
.tbList th.bg3 {
  background: #f9f9f9 !important;
}
.tbList td.bg3 {
  background: #f9f9f9 !important;
}

.tbList td .swBtn {
  margin: -2px 0;
}
.tbList td .swBtn span {
  font-size: 12px;
}
.tbList td .delBtn {
  margin: -5px 0;
}
.tbList td .totalPay {
  font-size: 18px;
  color: #333;
  line-height: 1.2;
  font-weight: 600;
}

.tbList td .hImg {
  display: inline-block;
  height: 24px;
  margin: -3px 0 -4px;
}
.tbList td select {
  font-size: 12px;
}

.tbList td.left {
  text-align: left;
  padding: 11px 10px 10px 10px;
}
.tbList td.right {
  text-align: right;
  padding: 11px 10px 10px 0;
}
.tbList td.Data {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}
.tbList td.thType {
  font-size: 12px;
  color: #333;
  line-height: 1.2;
  background: #f9f9f9;
}
.tbList td a {
  display: inline-block;
  color: #666;
}
.tbList td a:hover {
  text-decoration: underline;
}
.tbList tbody td select {
  width: 100%;
}
.tbList tbody.total tr td {
  background: #fafafa;
}

.tbList tfoot td {
  font-size: 12px;
  color: #666;
  line-height: 1.2;
  border-bottom: 1px #e4ebf1 solid;
  padding: 24px 0;
}

.tbList .fmRad-i:before {
  left: 3px;
}
.tbList .fmRad-i:after {
  left: 6px;
}
.tbList .fmChk-i:before {
  left: 3px;
}
.tbList .fmChk-i:after {
  left: 6px;
}
.tbList.interval {
  margin-top: 40px;
}

.tbList.detailMode th {
  border: 1px #cacaca solid;
}

/* table inner search */
.inSearch {
  width: 100%;
  margin: -4px 0 -4px 0;
  overflow: hidden;
}
.inSearch * {
  vertical-align: middle;
}
.inSearch .iBox {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 28px;
  padding: 0 45px 0 0;
  border: 1px #e5e5e5 solid;
  border-radius: 50px;
  overflow: hidden;
}
.inSearch .iBox input {
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  border: 0;
  padding: 0 0 0 20px;
  letter-spacing: 0;
}
.inSearch .iBox button {
  position: absolute;
  right: 12px;
  top: -1px;
  width: 28px;
  height: 28px;
  background: url("../imgs/btn_inner_search_magnifier.png") left top no-repeat;
}

/* table height */
.autoHeight {
  width: 100%;
  padding: 0 0 1px 0;
  overflow-y: auto;
} /* auto overflow */
body *::-webkit-scrollbar {
  width: 2px;
}
body *::-webkit-scrollbar-track {
  background: #ddd;
}
body *::-webkit-scrollbar-thumb {
  background: #666;
}

/* select list style */
.selectList {
  width: 100%;
  border-top: 1px #ebebeb solid;
}
.selectList:after {
  content: "";
  display: block;
  clear: both;
}
.selectList .seLx {
  position: relative;
  padding: 0 86px 0 25px;
  border-bottom: 1px #ebebeb solid;
}
.selectList .seLx .seDx {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}
.selectList .seLx .seDx .item1 {
  width: 35%;
  padding: 8px 0 5px 0;
}
.selectList .seLx .seDx .item2 {
  width: 30%;
  padding: 8px 0 5px 0;
}
.selectList .seLx .seDx .item3 {
  width: 20%;
  padding: 8px 0 5px 0;
}
.selectList .seLx .seDx .item4 {
  width: 15%;
  padding: 8px 0 5px 0;
}
.selectList .seLx .seDx .seBx {
  position: relative;
  padding: 3px 0 3px 40px;
}
.selectList .seLx .seDx .seBx .st {
  position: absolute;
  left: 0;
  top: 3px;
  font-size: 12px;
  color: #333;
  line-height: 1.2;
}
.selectList .seLx .seDx .seBx .data {
  width: 100%;
  padding: 0 20px 0 0;
}
.selectList .seLx .seDx .seBx .data p {
  font-size: 12px;
  color: #666;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.selectList .seLx .seDx .seBx .data .orderList {
  width: 100%;
  margin: -4px 0 0 0;
}
.selectList .seLx .seDx .seBx .data .orderList select {
  width: 65px;
  height: 20px;
  font-size: 12px;
  color: #666;
  letter-spacing: -0.5px;
  line-height: 20px;
  padding: 0 25px 0 0;
  text-align: center;
  border: 1px #ccc solid;
  border-radius: 0;
  background: url("../imgs/bg_select_list_type.png") right 6px top 52% #fff
    no-repeat;
  -webkit-appearance: none;
  appearance: none;
}
.selectList .seLx .seDx .seBx .data .colorPicker {
  width: 100%;
  margin: -4px 0 -2px 0;
}
.selectList .seLx .seDx .seBx .data .colorPicker input {
  width: 23px;
  height: 23px;
  padding: 0;
  margin: 0;
  background: #fff;
  border: 0;
}
.selectList .seLx .seDx .seBx .data .colorPicker label {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../imgs/bg_color_picker.png") left top no-repeat;
}

.selectList .seLx .comDx {
  position: relative;
  width: 100%;
  padding: 0 0 0 40px;
  margin: -2px 0 10px 0;
}
.selectList .seLx .comDx .st {
  position: absolute;
  left: 0;
  top: 3px;
  font-size: 12px;
  color: #333;
  line-height: 1.2;
}
.selectList .seLx .comDx textarea {
  height: 100px;
}

.selectList .seLx .delBtn {
  position: absolute;
  right: 34px;
  top: 15px;
}
.selectList .seLx .detailBtn {
  position: absolute;
  left: 20px;
  top: 50%;
  margin: -17px 0 0 0;
}
.delBtn:after {
  content: "";
  display: block;
  clear: both;
}
.delBtn button {
  width: 21px;
  height: 24px;
  background: url("../imgs/btn_select_list_del.png") left top no-repeat;
}
.detailBtn:after {
  content: "";
  display: block;
  clear: both;
}
.detailBtn button {
  width: 34px;
  height: 34px;
  background: url("../imgs/btn_select_list_detail.png") left top no-repeat;
}
.selectList .seLx.detailLx {
  padding: 0 86px 0 68px;
}

/* select list style */
.registList {
  width: 100%;
  padding: 0 26px;
}
.registList:after {
  content: "";
  display: block;
  clear: both;
}
.registList.interval {
  margin: 40px 0 0 0;
}

.registList .reBx {
  position: relative;
  width: 100%;
  min-height: 32px;
  margin: 0 0 10px 0;
  padding: 0 0 0 84px;
}
.registList .reBx .ab {
  position: absolute;
  left: 0;
  top: 8px;
}
.registList .reBx .ab span {
  font-size: 14px;
  color: #333;
  line-height: 1.2;
}
.registList .reBx .ab.ess:after {
  content: "*";
  position: absolute;
  left: -12px;
  top: 0;
  font-size: 13px;
  color: #ff0000;
}
.registList .reBx .ab * {
  vertical-align: middle;
}
.registList .reBx .ab .pview {
  width: 21px;
  height: 18px;
  background: url("../imgs/preview_icon.png") left top no-repeat;
  margin: 0 0 0 6px;
}
.registList .reBx .data {
  position: relative;
  font-size: 0;
  line-height: 0;
}
.registList .reBx .data p {
  font-size: 14px;
  color: #333;
  line-height: 32px;
}
.registList .reBx .data a {
  font-size: 14px;
  color: #333;
  line-height: 32px;
}
.registList .reBx .data a:hover {
  text-decoration: underline;
}

.registList .reBx .data .floadDiv {
  display: inline-block;
  margin: 0 0 0 10px;
}

.registList .reBx .data .period {
  display: inline-block;
  width: 260px;
}
.registList .reBx .data .period:after {
  content: "";
  display: block;
  clear: both;
}
.registList .reBx .data .period ul li {
  float: left;
}
.registList .reBx .data .period ul li input {
  padding-left: 0;
}
.registList .reBx .data .period ul li:nth-child(1) {
  width: 46%;
}
.registList .reBx .data .period ul li:nth-child(2) {
  width: 8%;
  text-align: center;
  font-size: 14px;
  color: #666;
  line-height: 32px;
}
.registList .reBx .data .period ul li:nth-child(3) {
  width: 46%;
}

.registList .reBx .data .tt {
  font-size: 14px;
  color: #333;
  line-height: 32px;
  margin: 0 0 2px 0;
}
.registList .reBx .data .iList {
  width: 100%;
  margin: 0 0 20px 0;
  overflow: hidden;
}
.registList .reBx .data .iList:last-child {
  margin: 0;
}
.registList .reBx .data .iList ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .reBx .data .iList ul li {
  position: relative;
  display: block;
  margin: 0 0 10px 0;
}
.registList .reBx .data .iList ul li:last-child {
  margin: 0;
}
.registList .reBx .data .fileBx {
  position: relative;
  padding: 0 132px 0 0;
}
.registList .reBx .data .fileBx:after {
  content: "";
  display: block;
  clear: both;
  *zoom: 1;
}
.registList .reBx .data .fileBx input[type="text"] {
  cursor: pointer;
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  border: 1px #d2d2d2 solid;
  border-radius: 8px;
  padding: 0 0 0 12px;
}
.registList .reBx .data .fileBx .hideCon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 20;
}
.registList .reBx .data .fileBx .hideCon:after {
  content: "";
  display: block;
  clear: both;
}
.registList .reBx .data .fileBx .fileHidden {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 32px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  cursor: pointer;
  background: #f3f5f6;
}
.registList .reBx .data .uploadBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.registList .reBx .data .templates {
  width: 100%;
  padding: 30px 32px;
  background: #f4f4f4;
  border-radius: 8px;
  overflow: hidden;
}
.registList .reBx .data .templates ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .reBx .data .templates ul li {
  float: left;
  position: relative;
  width: 20%;
  padding: 0 0 0 30px;
}
.registList .reBx .data .templates ul li .chk {
  width: 115px;
  height: 30px;
  text-align: center;
}
.registList .reBx .data .templates ul li .chk .fmRad + .fmRad-i {
  padding-left: 20px;
}
.registList .reBx .data .templates ul li .chk .fmRad-i:before {
  top: 0;
  width: 20px;
  height: 20px;
}
.registList .reBx .data .templates ul li .chk .fmRad:checked + .fmRad-i:after {
  width: 11px;
  height: 6px;
}
.registList .reBx .data .templates ul li .chk .fmRad-i:after {
  left: 4px;
  top: 6px;
}
.registList .reBx .data .templates ul li .number {
  position: absolute;
  left: 0;
  top: 30px;
  width: 21px;
  height: 21px;
  background: #000;
  border-radius: 100%;
  text-align: center;
}
.registList .reBx .data .templates ul li .number span {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  line-height: 22px;
}
.registList .reBx .data .textarea {
  height: 120px;
  line-height: 1.4;
}
.registList .reBx .data .textarea.high {
  height: 250px;
}
.registList .reBx .data .textarea[readonly] {
  min-height: 100px;
  border: 0;
  background: #fff;
}

.registList .reBx .data .snsList {
  width: 100%;
  margin: 0 0 20px 0;
  overflow: hidden;
}
.registList .reBx .data .snsList:last-child {
  margin: 0;
}
.registList .reBx .data .snsList ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .reBx .data .snsList ul li {
  position: relative;
  display: block;
  margin: 0 0 10px 0;
  padding: 0 0 0 40px;
}
.registList .reBx .data .snsList ul li .ico {
  position: absolute;
  left: 0;
  top: 0;
}
.registList .reBx .data .snsList ul li .ico span {
  font-size: 14px;
  color: #333;
  line-height: 32px;
  padding: 0 0 0 5px;
}
.registList .reBx .data .snsList ul li:last-child {
  margin: 0;
}

.registList .reBx .data .colorList {
  width: 100%;
  overflow: hidden;
}
.registList .reBx .data .colorList:last-child {
  margin: 0;
}
.registList .reBx .data .colorList ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .reBx .data .colorList ul li {
  position: relative;
  float: left;
  margin: 0 20px 10px 0;
  padding: 0 20px 0 80px;
}
.registList .reBx .data .colorList ul li:after {
  content: "|";
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: #333;
  line-height: 32px;
}
.registList .reBx .data .colorList ul li .st {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  color: #333;
  line-height: 32px;
}
.registList .reBx .data .colorList ul li .colorPicker {
  display: inline-block;
  padding: 4px 0 0 0;
}

.colorPicker {
  display: inline-block;
  padding: 4px 0 0 0;
}
.colorPicker input {
  width: 23px;
  height: 23px;
  padding: 0;
  margin: 0;
  background: #fff;
  border: 0;
}
.colorPicker label {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../imgs/bg_color_picker.png") left top no-repeat;
}

.registList .reBx .data .colorList ul li .colorPicker input {
  width: 23px;
  height: 23px;
  padding: 0;
  margin: 0;
  background: #fff;
  border: 0;
}
.registList .reBx .data .colorList ul li .colorPicker label {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../imgs/bg_color_picker.png") left top no-repeat;
}
.registList .reBx .data .colorList ul li:last-child:after {
  display: none;
}

.registList .reBx .data .bSearch {
  padding-top: 0;
}
.registList .reBx .data .bSearch .tit {
  font-size: 14px;
}
.registList .reBx .data .bSearch .bsBox {
  width: 300px;
  border-color: #d2d2d2;
}
.registList .reBx .data .bSearch .bsBox input {
  height: 32px;
  line-height: 32px;
}
.registList .reBx .data .httNode {
  font-size: 14px;
  margin-top: 30px;
}

.registList .reBx .data .length {
  position: absolute;
  right: -58px;
  bottom: 0;
  width: 50px;
  font-size: 12px;
  color: #333;
  line-height: 32px;
}
.registList .reBx .data .length span {
  font-size: 12px;
  color: #333;
  line-height: 32px;
}

.registList .reBx .data .hashDx {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
}
.registList .reBx .data .hashDx .item {
  width: 32%;
}
.registList .reBx .data .hashDx .item:nth-child(n + 4) {
  margin-top: 7px;
}

.registList .reBx .data .thirdDx {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
}
.registList .reBx .data .thirdDx .item {
  width: 32%;
}
.registList .reBx .data .thirdDx .item:nth-child(n + 4) {
  margin-top: 7px;
}

.registList .reBx .data .addUpload {
  position: relative;
  padding: 0 0 0 80px;
  margin: 10px 0 0 0;
}
.registList .reBx .data .addUpload .att {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  color: #333;
  line-height: 32px;
}
.addTxt {
  font-size: 12px;
  color: #ff0000;
  line-height: 1.2;
  padding: 10px 0 0 0;
}
.addTxt.r {
  text-align: right;
}
.fAddTxt {
  font-size: 13px;
  color: #ff0000;
  line-height: 1.4;
  padding: 0 0 30px 0;
}

.registList .reBx .data .fileList {
  position: relative;
}
.registList .reBx .data .fileList ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .reBx .data .fileList ul li {
  position: relative;
  float: left;
  margin: 0 28px 0 0;
  padding: 0 30px 0 0;
}
.registList .reBx .data .fileList ul li:after {
  content: "|";
  position: absolute;
  right: -10px;
  top: 50%;
  width: 1px;
  height: 12px;
  margin: -6px 0 0 0;
  background: #eee;
}
.registList .reBx .data .fileList ul li:last-child:after {
  display: none;
  margin: 0;
}
.registList .reBx .data .fileList ul li a {
  font-size: 13px;
  color: #000;
  line-height: 32px;
  font-weight: 600;
}
.registList .reBx .data .fileList ul li a:hover {
  text-decoration: underline;
}
.registList .reBx .data .fileList ul li button {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 25px;
  height: 25px;
  background: url("../imgs/btn_list_del.png") center top 50% no-repeat;
}
.registList .reBx .data .datepicker {
  padding-left: 0;
}

.registList .reBx .data .award {
  position: relative;
  width: 100%;
  margin: 0 0 10px 0;
}
.registList .reBx .data .award:after {
  content: "";
  display: block;
  clear: both;
}
.registList .reBx .data .award:last-child {
  margin: 0;
}
.registList .reBx .data .award ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .reBx .data .award ul li {
  float: left;
}
.registList .reBx .data .award ul li:nth-child(1) {
  width: 45%;
  padding: 0 10px 0 0;
}
.registList .reBx .data .award ul li:nth-child(2) {
  width: 35%;
  padding: 0 10px 0 0;
}
.registList .reBx .data .award ul li:nth-child(3) {
  width: 20%;
}
.registList .reBx .data .award ul li .datepicker {
  border: 1px #d2d2d2 solid;
  padding-left: 12px;
}
.registList .reBx .data .addPlus {
  position: absolute;
  right: -36px;
  top: 0;
  width: 32px;
  height: 32px;
  background: url("../imgs/btn_add_plus.png") left top no-repeat;
}

.registList .reBx .abDiv {
  position: absolute;
  left: 280px;
  top: 0;
  width: calc(100% - 280px);
}
.registList .reBx .abDiv ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .reBx .abDiv ul li {
  float: left;
  position: relative;
  width: 50%;
  padding: 0 0 0 100px;
}
.registList .reBx .abDiv ul li .stt {
  position: absolute;
  left: 0;
  top: 8px;
  width: 100px;
  text-align: right;
}
.registList .reBx .abDiv ul li .stt span {
  display: inline-block;
  font-size: 14px;
  color: #333;
  line-height: 1.2;
  padding: 0 18px 0 0;
}
.registList .reBx .abDiv ul li input {
  width: 100%;
}
.registList .reBx .abDiv ul li select {
  width: 100%;
}

.registList .reBx.interval {
  margin-top: 20px;
}
.registList .reBx.bigInterval {
  margin-top: 40px;
}
.registList .reBx.gap {
  padding: 0 0 0 130px;
  margin: 40px 0 10px 0;
}
.registList .reBx .inquiryTxt {
  font-size: 14px;
  color: #333;
  line-height: 1.4;
  background: #fff;
  padding: 12px;
}

.registList.longType .reBx {
  padding: 0 0 0 130px;
}

.registList .bsDx1 {
  position: relative;
  padding: 0 0 0 225px;
}
.registList .bsDx1 .imgArea {
  position: absolute;
  left: 0;
  top: 0;
  width: 225px;
  height: 225px;
  background: #f7f7f7;
}
.registList .bsDx1 .imgArea img {
  width: 225px;
  height: 225px;
}
.registList .bsForm1:after {
  content: "";
  display: block;
  clear: both;
}
.registList .bsForm1 > ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .bsForm1 > ul > li {
  position: relative;
  float: left;
  min-height: 32px;
  padding: 0 0 7px 100px;
}
.registList .bsForm1 > ul > li .ab {
  position: absolute;
  left: 40px;
  top: 8px;
}
.registList .bsForm1 > ul > li .ab span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #333;
  line-height: 1.2;
}
.registList .bsForm1 > ul > li .ab.ess span:after {
  content: "*";
  position: absolute;
  right: -10px;
  top: 0;
  font-size: 13px;
  color: #ff0000;
}
.registList .bsForm1 > ul > li .data {
  position: relative;
  font-size: 0;
  line-height: 0;
}
.registList .bsForm1 > ul > li .data .addData {
  position: relative;
  padding: 0 0 0 75px;
  margin: 7px 0 0 0;
}
.registList .bsForm1 > ul > li .data .addData .ab {
  left: 0;
}
.registList .bsForm1 > ul.twice > li {
  width: 50%;
}
.registList .bsForm1 > ul.third > li {
  width: 33.333%;
}
.registList .bsForm1 .datepicker {
  border: 1px #d2d2d2 solid;
}

.registList .bsDx2 {
  position: relative;
  padding: 50px 0 33px 0;
}
.registList .bsForm2:after {
  content: "";
  display: block;
  clear: both;
}
.registList .bsForm2 ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .bsForm2 > ul > li {
  position: relative;
  float: left;
  width: 50%;
  min-height: 32px;
  padding: 0 0 7px 60px;
}
.registList .bsForm2 > ul > li .ab {
  position: absolute;
  left: 0;
  top: 8px;
}
.registList .bsForm2 > ul > li .ab span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #333;
  line-height: 1.2;
}
.registList .bsForm2 > ul > li .ab.ess span:after {
  content: "*";
  position: absolute;
  right: -10px;
  top: 0;
  font-size: 13px;
  color: #ff0000;
}
.registList .bsForm2 > ul > li .data {
  position: relative;
  font-size: 0;
  line-height: 0;
}
.registList .bsForm2 > ul > li .data > ul {
  padding: 0 20px 0 0;
}
.registList .bsForm2 > ul > li .data > ul li {
  position: relative;
  float: left;
  width: 50%;
  padding: 0 10px 0 0;
}
.registList .bsForm2 > ul > li .data .trans {
  padding: 0 0 0 40px;
}

.registList .bsForm2 > ul > li.free {
  padding: 0 0 7px 15px;
}
.registList .bsForm2 > ul > li.free .ab {
  position: relative;
  top: 0;
  display: inline-block;
  margin: 0 10px 0 0;
}
.registList .bsForm2 > ul > li.free .ab span {
  line-height: 32px;
}
.registList .bsForm2 > ul > li.free * {
  vertical-align: middle;
}
.registList .bsForm2 > ul > li.free .data {
  display: inline-block;
}

.registList .wKdc {
  width: 200px;
}
.registList .wPic {
  width: 226px;
}
.registList .w80p {
  width: 80%;
}

.registList .bsSeries {
  position: relative;
  min-height: 32px;
  padding: 32px 0 32px 90px;
  border-top: 1px #ebebeb solid;
  border-bottom: 1px #ebebeb solid;
}
.registList .bsSeries:after {
  content: "";
  display: block;
  clear: both;
}
.registList .bsSeries .sCheck {
  position: absolute;
  left: 0;
  top: 50%;
  margin: -16px 0 0 0;
}
.registList .bsSeries .sCheck label {
  font-size: 14px;
  color: #333;
  line-height: 32px;
  padding-right: 25px;
  padding-left: 0;
}
.registList .bsSeries .sCheck .fmChk-i:before {
  left: auto;
  right: 0;
  top: 8px;
}
.registList .bsSeries .sCheck .fmChk-i:after {
  left: auto;
  right: 4px;
  top: 12px;
}
.registList .bsSeries .sForm:after {
  content: "";
  display: block;
  clear: both;
}
.registList .bsSeries .sForm ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .bsSeries .sForm ul li {
  position: relative;
  float: left;
}
.registList .bsSeries .sForm ul li .ab {
  position: absolute;
  left: 0;
  top: 8px;
}
.registList .bsSeries .sForm ul li .ab span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #333;
  line-height: 1.2;
}
.registList .bsSeries .sForm ul li:nth-child(1) {
  width: 43%;
  padding: 0 0 0 100px;
}
.registList .bsSeries .sForm ul li:nth-child(1) .ab {
  left: 35px;
}
.registList .bsSeries .sForm ul li:nth-child(2) {
  width: 37%;
  padding: 0 0 0 150px;
}
.registList .bsSeries .sForm ul li:nth-child(2) .ab {
  left: 35px;
}
.registList .bsSeries .sForm ul li:nth-child(3) {
  width: 20%;
  padding: 0 0 0 78px;
}
.registList .bsSeries .sForm ul li:nth-child(3) .ab {
  left: 40px;
}

.registList .bsDx3 {
  position: relative;
  margin: 40px 0 40px 0;
}
.registList .bsDx3:before {
  content: "";
  position: absolute;
  left: 25%;
  top: 0;
  width: 1px;
  height: 100%;
  background: #ebebeb;
}
.registList .bsDx3:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 1px;
  height: 100%;
  background: #ebebeb;
}
.registList .bsForm3:after {
  content: "";
  display: block;
  clear: both;
}
.registList .bsForm3 .bsItem {
  float: left;
}
.registList .bsForm3 .bsItem:nth-child(1) {
  width: 25%;
  padding: 0 40px 0 0;
}
.registList .bsForm3 .bsItem:nth-child(2) {
  width: 25%;
  padding: 0 40px;
}
.registList .bsForm3 .bsItem:nth-child(3) {
  width: 50%;
  padding: 0 0 0 40px;
}
.registList .bsForm3 .bsItem ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .bsForm3 .bsItem ul li {
  display: block;
  position: relative;
  min-height: 32px;
  padding: 0 0 7px 78px;
}
.registList .bsForm3 .bsItem ul li .ab {
  position: absolute;
  left: 0;
  top: 8px;
}
.registList .bsForm3 .bsItem ul li .ab span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #333;
  line-height: 1.2;
}
.registList .bsForm3 .bsItem ul li .ab.ess span:after {
  content: "*";
  position: absolute;
  right: -10px;
  top: 0;
  font-size: 13px;
  color: #ff0000;
}
.registList .bsForm3 .bsItem ul li .data {
  position: relative;
  font-size: 0;
  line-height: 0;
}
.registList .bsForm3 .bsItem ul li .data.addSrc {
  padding: 0 0 0 118px;
}
.registList .bsForm3 .bsItem ul li .fileBx {
  position: relative;
  padding: 0 122px 0 0;
}
.registList .bsForm3 .bsItem ul li .fileBx:after {
  content: "";
  display: block;
  clear: both;
  *zoom: 1;
}
.registList .bsForm3 .bsItem ul li .fileBx .hideCon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 20;
}
.registList .bsForm3 .bsItem ul li .fileBx .hideCon:after {
  content: "";
  display: block;
  clear: both;
}
.registList .bsForm3 .bsItem ul li .fileBx .fileHidden {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 32px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  cursor: pointer;
  background: #f3f5f6;
}
.registList .bsForm3 .bsItem ul li .uploadBtn {
  position: absolute;
  right: 0;
  top: 0;
  width: 110px;
  height: 32px;
  background: #fff;
  border: 1px #000 solid;
}
.registList .bsForm3 .bsItem ul li .uploadBtn span {
  display: inline-block;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  line-height: 32px;
}

.registList .couponDx {
  position: relative;
  padding: 0 0 12px 0;
}
.registList .couponDx:first-child {
  margin: 10px 0 0 0;
}
.registList .couponDx:after {
  content: "";
  display: block;
  clear: both;
}
.registList .couponDx > ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.registList .couponDx > ul:after {
  content: "";
  display: block;
  clear: both;
}
.registList .couponDx > ul > li {
  position: relative;
  float: left;
  min-height: 32px;
  width: 50%;
  padding: 0 0 0 100px;
}
.registList .couponDx > ul > li .ab {
  position: absolute;
  left: 0;
  top: 8px;
}
.registList .couponDx > ul > li .ab span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #333;
  line-height: 1.2;
}
.registList .couponDx > ul > li .ab.ess span:after {
  content: "*";
  position: absolute;
  right: -10px;
  top: 0;
  font-size: 13px;
  color: #ff0000;
}
.registList .couponDx > ul > li .data {
  position: relative;
  font-size: 0;
  line-height: 0;
}
.registList .couponDx > ul > li:nth-child(2) .ab {
  left: 20px;
}

.couSearch {
  width: 100%;
  margin: 0 0 6px;
  overflow: hidden;
}
.couSearch * {
  vertical-align: middle;
}
.couSearch .iBox {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 32px;
  padding: 0 45px 0 0;
  border: 1px #d2d2d2 solid;
  border-radius: 8px;
  overflow: hidden;
}
.couSearch .iBox input {
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  border: 0;
  padding: 0 0 0 12px;
  letter-spacing: 0;
}
.couSearch .iBox button {
  position: absolute;
  right: 8px;
  top: 0;
  width: 32px;
  height: 32px;
  background: url("../imgs/btn_inner_search_magnifier.png") left top no-repeat;
}

/* divide */
.divideWrap {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 20px 0 0 0;
  overflow: hidden;
}
.divideWrap .ldivide {
  width: 30%;
}
.divideWrap .rdivide {
  width: 70%;
  padding: 0 0 0 15px;
}

.divideMenu {
  width: 100%;
  margin: 20px 0 0 0;
  overflow: hidden;
}
.divideMenu .mDive {
  float: left;
  width: 30%;
  margin: 0 40px 0 0;
}
.divideMenu .mDive .tbList th span {
  font-size: 14px;
}
.divideMenu .mDive .tbList td {
  text-align: left;
  padding-left: 20px;
}
.divideMenu .mDive .tbList a {
  position: relative;
}
/* .divideMenu .mDive .tbList a:after {content:'��'; position:absolute; right:-15px; top:1px; font-size:11px; color:#333;} */

.divideAuth {
  width: 100%;
  margin: 20px 0 0 0;
  overflow: hidden;
}
.divideAuth .authDive:nth-child(1) {
  float: left;
  width: 50%;
  padding: 0 10px 0 0;
}
.divideAuth .authDive:nth-child(2) {
  float: left;
  width: 50%;
  padding: 0 0 0 10px;
}
.divideAuth .authDive .btnArea {
  padding: 0 0 10px 0;
}
.divideAuth .authDive .scrollDx {
  height: 400px;
}
.divideAuth .authDive .treeBx {
  width: 100%;
  height: 340px;
  text-align: left;
  overflow-y: auto;
}
.divideAuth .authDive .treeBx ul {
  margin: 5px 0 0 20px;
}
.divideAuth .authDive .treeBx ul:after {
  content: "";
  display: block;
  clear: both;
}
.divideAuth .authDive .treeBx ul li {
  padding: 5px 0;
}
.divideAuth .authDive .treeBx .fmChk-i {
  font-size: 12px;
}
.divideAuth .authDive .treeBx .fmChk-i:before {
  top: 0;
}
.divideAuth .authDive .treeBx .fmChk-i:after {
  top: 4px;
}

/* authority */
.authority {
  width: 100%;
  overflow: hidden;
}
.authority .addTxt {
  padding: 0;
}
.authority .authbx {
  width: 100%;
  border: 1px #d2d2d2 solid;
  background: #f9f9f9;
  padding: 40px 0;
  margin: 20px 0 0 0;
  overflow: hidden;
}
.authority .authbx ul:after {
  content: "";
  display: block;
  clear: both;
}
.authority .authbx ul li {
  float: left;
  width: 25%;
  text-align: center;
}
.authority .authbx ul li .fmRad-i:before {
  top: 0;
}
.authority .authbx ul li .fmRad-i:after {
  top: 4px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}
input:checked + .slider {
  background-color: #2189ff;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2189ff;
}
input:checked + .slider:before {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
/* Rounded sliders */
.slider.round {
  border-radius: 28px;
}
.slider.round:before {
  border-radius: 50%;
}

/* view data */
.vWrap {
  width: 100%;
  padding: 16px 0;
  border-top: 2px #000 solid;
  border-bottom: 1px #ebebeb solid;
  overflow: hidden;
}
.vWrap .tit {
  font-size: 14px;
  color: #333;
  line-height: 1.1;
  border-bottom: 1px #ebebeb solid;
  padding: 4px 0 18px 30px;
  margin: 0 0 15px 0;
}
.vWrap .vData {
  display: flex;
  flex-wrap: nowrap;
}
.vWrap .vData .vItem {
  width: 44%;
  padding: 0 0 0 38px;
}
.vWrap .vData .vItem .vBx {
  position: relative;
  width: 100%;
  padding: 7px 0 7px 100px;
}
.vWrap .vData .vItem .vBx:after {
  content: "";
  display: block;
  clear: both;
}
.vWrap .vData .vItem .vBx .vtt {
  position: absolute;
  left: 0;
  top: 7px;
  font-size: 12px;
  color: #585858;
  line-height: 1.2;
  font-weight: 600;
}
.vWrap .vData .vItem .vBx .vtx {
  font-size: 12px;
  color: #333;
  line-height: 1.2;
}
.vWrap .vData .vItem .vBx .vtx a {
  font-size: 12px;
  color: #333;
  font-weight: 600;
}
.vWrap .vData .vItem .vBx .vtx a:hover {
  text-decoration: underline;
}
.vWrap .vData .vItem .vBx .vtx ul {
  margin: -7px 0;
}
.vWrap .vData .vItem .vBx .vtx ul:after {
  content: "";
  display: block;
  clear: both;
}
.vWrap .vData .vItem .vBx .vtx ul li {
  position: relative;
  font-size: 12px;
  color: #333;
  line-height: 1.2;
  padding: 7px 0 7px 100px;
}
.vWrap .vData .vItem .vBx .vtx ul li .dtt {
  position: absolute;
  left: 0;
  top: 7px;
  font-size: 12px;
  color: #585858;
  line-height: 1.2;
}
.vWrap .vData .vItem .vBx .vtx ul li .dtx {
  font-size: 12px;
  color: #333;
  line-height: 1.2;
}
.vWrap .vData .vItem .vBx .vtx ul li .dtx .totalWon {
  display: inline-block;
  font-size: 18px;
  color: #218aff;
  font-weight: 600;
  margin: -2px 0 0 0;
}
.vWrap .vData .vItem .vBx .vtx ul li .dtx * {
  vertical-align: middle;
}
.vWrap .vData .vItem .vBx .vtx ul li.full {
  padding: 7px 0;
}
.vWrap .vData .vItem .vBx .vtx.add {
  margin: 15px 0 10px;
}

/* twice box */
.twiceBx {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}
.twiceBx .tBx {
  width: 48.5%;
}
.twiceBx .tBx .vWrap {
  border-bottom: 0;
}
.twiceBx .tBx {
  border-bottom: 1px #ebebeb solid;
}
.twiceBx .tBx .vWrap .vData .vItem {
  width: 100%;
  padding: 0 0 0 30px;
}
.baseBx {
  width: 100%;
  overflow: hidden;
}

/* pay infomation */
.payInfo {
  position: relative;
  width: 100%;
  padding: 42px 0;
  border-top: 2px #000 solid;
  border-bottom: 1px #ebebeb solid;
  overflow: hidden;
}
.payInfo .pData {
  width: 100%;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.payInfo .pData .pItem {
  position: relative;
  float: left;
  width: 14.285%;
  text-align: center;
}
.payInfo .pData .pItem .won {
  font-size: 25px;
  color: #333;
  line-height: 1.2;
  padding: 0 0 10px 0;
}
.payInfo .pData .pItem .won * {
  vertical-align: middle;
}
.payInfo .pData .pItem .won span {
  display: inline-block;
  font-size: 21px;
  margin: -2px 0 0 0;
}
.payInfo .pData .pItem .won strong {
  color: #218aff;
}
.payInfo .pData .pItem .info {
  font-size: 12px;
  color: #585858;
  line-height: 1.2;
}
.payInfo .pData .pItem.plus:after {
  content: "";
  position: absolute;
  left: -8px;
  top: 50%;
  width: 16px;
  height: 15px;
  margin: -8px 0 0 0;
  background: url("../imgs/bg_pay_plus.png") left top no-repeat;
}
.payInfo .pData .pItem.minus:after {
  content: "";
  position: absolute;
  left: -8px;
  top: 50%;
  width: 16px;
  height: 15px;
  margin: -8px 0 0 0;
  background: url("../imgs/bg_pay_minus.png") left top no-repeat;
}
.payInfo .pData .pItem.equal:after {
  content: "";
  position: absolute;
  left: -8px;
  top: 50%;
  width: 16px;
  height: 15px;
  margin: -8px 0 0 0;
  background: url("../imgs/bg_pay_equal.png") left top no-repeat;
}
.payInfo .pData .pItem.total:after {
  content: "";
  position: absolute;
  left: -1px;
  top: 0;
  width: 1px;
  height: 56px;
  background: #ebebeb;
}

/* sub tabs */
.listTabs {
  position: relative;
  width: 100%;
  margin: 0 0 25px 0;
  overflow: hidden;
}
.listTabs ul:after {
  content: "";
  display: block;
  clear: both;
}
.listTabs ul {
  border-bottom: 2px solid #2872af;
}
.listTabs ul li:first-child {
  border-left: 1px solid #e0e0e0;
}
.listTabs ul li {
  float: left;
  width: 182px;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  position: relative;
  background-color: #fff;
}
.listTabs > ul > li > a {
  display: block;
  font-size: 15px;
  color: #333;
  font-weight: 600;
  text-align: center;
  padding: 17px 0 14px;
}
.listTabs ul li.on {
  background-color: #fff;
  border-right: 2px solid #005cc5;
  border-left: 2px solid #005cc5;
  border-top: 2px solid #005cc5;
}
.listTabs ul li.on:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  border: 2px solid #fff;
}
.listTabs ul li.on a {
  color: #005cc5;
  padding: 16px 0 14px;
}
.listTabs .newAppBtn {
  position: absolute;
  right: 0;
  top: 0;
}

/* popup */
.layerPop {
  position: relative;
  border: 1px #2577ad solid;
  border-radius: 12px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
  z-index: 200;
}
.layerPop .lyInner {
  position: relative;
  width: 100%;
  padding: 0 34px 36px;
  background: #fff;
  border-radius: 12px;
}
.layerPop .lyInner:after {
  content: "";
  display: block;
  clear: both;
}

.layerPop .lyHead {
  width: 100%;
  padding: 0 0 20px 0;
  overflow: hidden;
}
.layerPop .lyHead .lytt {
  padding: 30px 0 10px 0;
  border-bottom: 1px #000 solid;
}
.layerPop .lyHead .lytt span {
  display: block;
  font-size: 18px;
  color: #333;
  line-height: 1.2;
}
.layerPop .lyHead .closedBtn {
  position: absolute;
  right: 25px;
  top: 12px;
  width: 40px;
  height: 40px;
  background: url("../imgs/btn_layer_closed.png") left top no-repeat;
}
.layerPop .lyBody {
  width: 100%;
  overflow: hidden;
}

.layerPop .lyNoti {
  width: 100%;
  padding: 20px 0 10px 0;
  text-align: center;
  overflow: hidden;
}
.layerPop .lyNoti p {
  display: block;
  font-size: 16px;
  color: #333;
  line-height: 1.4;
  word-break: normal;
}

.layerPop .lySample {
  width: 100%;
  text-align: center;
  overflow: hidden;
}
.layerPop .lySample img {
  max-width: 100%;
}

.layerPop .popBtnArea {
  width: 100%;
  text-align: center;
  margin: 30px 0 0 0;
}
.layerPop .popBtnArea ul {
  font-size: 0;
  line-height: 0;
}
.layerPop .popBtnArea ul:after {
  content: "";
  display: block;
  clear: both;
}
.layerPop .popBtnArea ul li {
  display: inline-block;
  margin: 0 4px;
}

.layerPop .categoryDiv {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.layerPop .categoryDiv .cDivs {
  width: 32%;
}
.layerPop .categoryDiv .cDivs .tt {
  font-size: 14px;
  color: #333;
  line-height: 1.2;
  padding: 0 0 8px 0;
}
.layerPop .categoryDiv .cDivs .tt u {
  font-size: 14px;
  color: #ff0000;
  line-height: 1.2;
  text-decoration: none;
}
.layerPop .categoryDiv .cDivs .list {
  padding: 20px 14px;
  border: 1px #d2d2d2 solid;
  border-radius: 10px;
}
.layerPop .categoryDiv .cDivs .list select {
  height: auto;
  /* background: url(none); */
  border: 0;
  font-size: 12px;
  color: #585858;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
}
.layerPop .categoryDiv .cDivs .list select:focus {
  outline: none !important;
}
.layerPop .categoryDiv .cDivs .list select option {
  padding: 4px 0;
}

.layerPop .aNoti {
  font-size: 14px;
  color: #ff0000;
  line-height: 1.2;
  margin: 27px 0 15px 0;
  padding: 27px 0 0 0;
  border-top: 1px #d2d2d2 solid;
}
.layerPop .aNewbtn {
  width: 100%;
  text-align: center;
  padding: 15px 0 23px 0;
  border-bottom: 1px #d2d2d2 solid;
}
.layerPop .aNewbtn button {
  width: 100px;
  height: 30px;
  background: #838383;
  border-radius: 50px;
  text-align: center;
}
.layerPop .aNewbtn button span {
  font-size: 14px;
  color: #fff;
  line-height: 30px;
}

/* popup size */
.layerPop.mini {
  width: 400px;
}
.layerPop.base {
  width: 600px;
}
.layerPop.mid {
  width: 700px;
}

/************* common ****************/

/* input */
input[type="text"] {
  width: 100%;
  height: 32px;
  font-size: 14px;
  color: #333;
  line-height: 32px;
  background: #fff;
  padding: 0 0 0 12px;
  border: 1px #d2d2d2 solid;
  border-radius: 8px;
  margin: 0;
  box-sizing: border-box;
  vertical-align: middle;
}
input[type="password"] {
  width: 100%;
  height: 32px;
  font-size: 14px;
  color: #333;
  line-height: 32px;
  background: #fff;
  padding: 0 0 0 12px;
  border: 1px #d2d2d2 solid;
  border-radius: 8px;
  margin: 0;
  box-sizing: border-box;
  vertical-align: middle;
}
input[type="text"]:focus,
input[type="password"]:focus {
  outline: 0;
  border-color: #000 !important;
}
input[type="checkbox"] {
  opacity: 0;
}
input[type="radio"] {
  opacity: 0;
}
input.datepicker {
  padding: 0 40px 0 14px;
  border: 0;
  background: url("../imgs/bg_datepicker.png") right 10px top 50% #fff no-repeat;
}

/* -placeholder */
input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #8e8e8e;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #8e8e8e;
  opacity: 1;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #8e8e8e;
  opacity: 1;
}
input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8e8e8e;
}

textarea {
  width: 100%;
  height: 120px;
  font-size: 14px;
  color: #333;
  line-height: 1.4;
  background: #fff;
  padding: 12px;
  border: 1px #d2d2d2 solid;
  border-radius: 8px;
  margin: 0;
  box-sizing: border-box;
  vertical-align: middle;
}
textarea::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #8e8e8e;
}
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #8e8e8e;
  opacity: 1;
}
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #8e8e8e;
  opacity: 1;
}
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8e8e8e;
}
textarea::-ms-input-placeholder {
  /* Internet Explorer Edge  */
  color: #8e8e8e;
}
textarea:focus {
  outline: 0;
  border-color: #2f2e2b;
}
input[readonly] {
  background-color: #eee;
}
input[disabled] {
  background-color: #eee;
  color: #333;
  font-weight: 500;
}
::-webkit-file-upload-button {
  cursor: pointer;
}
.textEdit {
  width: 100%;
  overflow: hidden;
}
.textEdit textarea {
  height: 300px;
}

select {
  width: 100%;
  height: 32px;
  font-size: 14px;
  color: #333;
  letter-spacing: -0.5px;
  line-height: 32px;
  padding: 0 22px 0 10px;
  border: 1px #d2d2d2 solid;
  border-radius: 8px;
  background: url("../imgs/bg_select_type.png") right 15px center #fff no-repeat;
  -webkit-appearance: none;
  appearance: none;
}
select option[value=""][disabled] {
  display: none;
}
select:invalid {
  color: #8e8e8e;
}
select[disabled] {
  background-color: #eee;
}

/* input width */
input[type="text"].wBase,
input[type="password"].wBase,
select.wBase {
  width: 250px;
}
input[type="text"].w280,
input[type="password"].w280,
select.w280 {
  width: 280px;
}
input[type="text"].w286,
input[type="password"].w286,
select.w286 {
  width: 286px;
}
input[type="text"].w500,
input[type="password"].w500,
select.w500 {
  width: 500px;
}
input[type="text"].w190,
input[type="password"].w190,
select.w190 {
  width: 190px !important;
}
input[type="text"].w140,
input[type="password"].w140,
select.w140 {
  width: 140px;
}
input[type="text"].wDate,
input[type="password"].wDate,
select.wDate {
  width: 130px !important;
}
input[type="text"].wDateBd,
input[type="password"].wDateBd,
select.wDateBd {
  width: 130px !important;
  border: 1px #d2d2d2 solid;
  padding-left: 12px !important;
}
input[type="text"].wNum,
input[type="password"].wNum {
  width: 150px;
  text-align: center;
  padding: 0 5px;
}

/* radio checkbox */
.fmRad,
.fmChk {
  position: absolute;
  width: 17px;
  height: 17px;
  padding: 0;
  margin: 0;
  opacity: 0;
  box-sizing: border-box;
}
.fmChk {
  position: relative;
}
.fmRad-i:before,
.fmRad-i:after,
.fmChk-i:before,
.fmChk-i:after {
  content: "";
  position: absolute;
  box-sizing: border-box;
}

.fmRad + .fmRad-i {
  display: inline-block;
  position: relative;
  width: auto;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  letter-spacing: -0.5px;
  line-height: 17px;
  box-sizing: border-box;
  padding-left: 23px;
}
.fmRad-i:before {
  left: 0;
  top: -8px;
  width: 15px;
  height: 15px;
  border: 1px #9e9e9e solid;
  background: #fff;
  border-radius: 100%;
}
.fmRad:checked + .fmRad-i:before {
  background: #2189ff;
  border-color: #2189ff;
}
.fmRad:checked + .fmRad-i:after {
  width: 8px;
  height: 5px;
  border: 0 #fff solid;
  border-width: 0 0 2px 2px;
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.fmRad-i:after {
  left: 3px;
  top: -4px;
}

.fmChk + .fmChk-i {
  display: inline-block;
  position: relative;
  width: auto;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  letter-spacing: -0.5px;
  line-height: 17px;
  box-sizing: border-box;
  padding-left: 23px;
}
.fmChk-i:before {
  left: 0;
  top: -20px;
  width: 15px;
  height: 15px;
  border: 1px #9e9e9e solid;
  border-radius: 100%;
}
.fmChk:checked + .fmChk-i:before {
  background: #2189ff;
  border-color: #2189ff;
}
.fmChk:checked + .fmChk-i:after {
  width: 8px;
  height: 5px;
  border: 0 #fff solid;
  border-width: 0 0 2px 2px;
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.fmChk-i:after {
  left: 3px;
  top: -16px;
}

/* btn area */
.btnArea {
  position: relative;
  width: 100%;
  min-height: 30px;
  padding: 15px 0 0 0;
  text-align: center;
}
.btnArea:after {
  content: "";
  display: block;
  clear: both;
}
.btnArea .lDiv {
  float: left;
}
.btnArea .lDiv button {
  margin: 0 8px 0 0;
}
.btnArea .rDiv {
  float: right;
}
.btnArea .rDiv button {
  margin: 0 0 0 8px;
}

.btnCArea {
  position: relative;
  width: 100%;
  min-height: 30px;
  font-size: 0;
  line-height: 0;
  padding: 20px 0 5px 0;
  text-align: center;
}
.btnCArea button {
  margin: 0 4px;
}

/* btn base */
.blackBtn {
  width: 84px;
  height: 30px;
  background: #232323;
  text-align: center;
  border-radius: 50px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.blackBtn span {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  line-height: 30px;
}
.blackBtn.wide {
  width: auto;
  height: 40px;
}
.blackBtn.wide span {
  font-size: 16px;
  padding: 0 32px;
  line-height: 40px;
}
.blackBtn.wAuto {
  width: auto;
}
.blackBtn.wAuto span {
  padding: 0 32px;
}

.whiteBtn {
  width: 84px;
  height: 30px;
  background: #fff;
  text-align: center;
  border-radius: 50px;
  border: 1px #232323 solid;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.whiteBtn span {
  display: inline-block;
  font-size: 14px;
  color: #232323;
  font-weight: 600;
  line-height: 30px;
}
.whiteBtn.wide {
  width: auto;
  height: 40px;
}
.whiteBtn.wide span {
  font-size: 16px;
  padding: 0 32px;
  line-height: 40px;
}
.whiteBtn.wAuto {
  width: auto;
}
.whiteBtn.wAuto span {
  padding: 0 32px;
}

.uploadBtn {
  width: 122px;
  height: 32px;
  background: #232323;
  text-align: center;
  border-radius: 50px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.uploadBtn span {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  line-height: 32px;
}
.uploadBtn.wide {
  height: 35px;
}
.uploadBtn.wide span {
  font-size: 16px;
  line-height: 35px;
}

/* search btn */
.swBtn {
  width: auto;
  height: 22px;
  background: #fff;
  text-align: center;
  border: 1px #111 solid;
  border-radius: 10px;
  margin: 0 0 0 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.swBtn span {
  font-size: 14px;
  color: #333;
  line-height: 20px;
  padding: 0 18px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.swBtn:hover {
  background: #111;
}
.swBtn:hover span {
  color: #fff;
}

/* new app btn */
.newAppBtn {
  width: 180px;
  height: 35px;
  background: #fff;
  text-align: center;
  border: 1px #3091ff solid;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.newAppBtn span {
  font-size: 16px;
  color: #000;
  line-height: 35px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.newAppBtn:hover {
  background: #3091ff;
}
.newAppBtn:hover span {
  color: #fff;
}
.newAppBtn.wAuto {
  width: auto;
}
.newAppBtn.wAuto span {
  padding: 0 32px;
}

/* cancel black btn */
.canblackBtn {
  width: 180px;
  height: 35px;
  background: #fff;
  text-align: center;
  border: 1px #000 solid;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.canblackBtn span {
  font-size: 16px;
  color: #000;
  line-height: 35px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.canblackBtn:hover {
  background: #000;
}
.canblackBtn:hover span {
  color: #fff;
}
.canblackBtn.wAuto {
  width: auto;
}
.canblackBtn.wAuto span {
  padding: 0 32px;
}

/* prev app btn */
.prevBtn {
  width: 180px;
  height: 35px;
  background: #fff;
  text-align: center;
  border: 1px #3091ff solid;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.prevBtn span {
  font-size: 16px;
  color: #000;
  line-height: 35px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.prevBtn:hover {
  background: #3091ff;
}
.prevBtn:hover span {
  color: #fff;
}

/* file */
.fileBox {
  display: inline-block;
  position: relative;
}
.fileBox:after {
  content: "";
  display: block;
  clear: both;
  *zoom: 1;
}
.fileBox input[type="text"] {
  cursor: pointer;
}
/* .fileBox input[type='text'].essential {background:url('../imgs/bg_inform_noti.png') 16px 50% no-repeat; padding:0 0 0 32px;} */
.fileBox .hideCon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 20;
}
.fileBox .hideCon:after {
  content: "";
  display: block;
  clear: both;
}
.fileBox .fileHidden {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 40px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  cursor: pointer;
  background: #f3f5f6;
}

/* paging */
.pagingArea {
  width: 100%;
  overflow: hidden;
  padding: 60px 0 0 0;
}
.pagingArea .paging {
  width: 100%;
  overflow: hidden;
  font-size: 0;
  text-align: center;
}
.pagingArea .paging a {
  position: relative;
  margin: 0 5px;
  display: inline-block;
  width: 38px;
  height: 38px;
  text-align: center;
  font-size: 1.66666rem;
  color: #525252;
  line-height: 38px;
  background: #fff;
  border: 1px #e5e5e5 solid;
}
.pagingArea .paging a.on {
  color: #fff;
  font-weight: normal;
  background: #84754d;
  border: 1px #84754d solid;
}
.pagingArea .paging a.n {
  margin: 0 5px;
}
.pagingArea .paging a.n img {
  vertical-align: top;
}

/* datapicker customizing */
#datepicker:focus > .ui-datepicker {
  display: block;
}
.ui-widget-header {
  border: 0px solid #ddd;
  background: #fff;
}
.ui-datepicker-calendar > thead > tr > th {
  font-size: 11px !important;
}
.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 17px 0 7px 0;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 0px solid #c5c5c5;
  background-color: transparent;
  font-weight: normal;
  color: #454545;
  text-align: center;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 0em;
  line-height: 16px;
  text-align: center;
  font-size: 13px;
  padding: 0px;
  font-weight: 600;
  color: #1e76af;
}
.ui-datepicker {
  display: none;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0 10px;
  padding-bottom: 10px;
  width: 230px;
}
.ui-widget.ui-widget-content {
  border: 1px solid #e8e8e8;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.29);
}

.ui-datepicker-prev,
.ui-datepicker-next {
  cursor: pointer;
}
.ui-datepicker-next {
  float: right;
}
.ui-state-disabled {
  cursor: auto;
  color: hsla(0, 0%, 80%, 1);
}
.ui-datepicker-title {
  text-align: center;
  padding: 10px;
  font-weight: 100;
  font-size: 20px;
}
.ui-datepicker-calendar {
  width: 100%;
}
.ui-datepicker-calendar > thead > tr > th {
  font-weight: normal;
}
.ui-datepicker-calendar > tbody > tr > td > a {
  color: #000;
  font-size: 12px !important;
  text-decoration: none;
}
.ui-datepicker-calendar > tbody > tr > .ui-state-disabled:hover {
  cursor: auto;
  background-color: #fff;
}
.ui-datepicker-calendar > tbody > tr > td {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-weight: 100;
  text-align: center;
  font-size: 12px;
}
.ui-datepicker-calendar > tbody > tr > td:hover {
  background-color: transparent;
  opacity: 0.6;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  border: 0px solid #cccccc;
  background-color: transparent;
  font-weight: normal;
  color: #2b2b2b;
}

.ui-widget-header .ui-icon {
  background-image: url("../imgs/bg_datapicker_month.png");
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  width: 36px;
  height: 36px;
}
.ui-icon-circle-triangle-e {
  background-position: -36px 0px;
}
.ui-icon-circle-triangle-w {
  background-position: -0px -0px;
}
.ui-icon {
  width: 36px;
  height: 36px;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  left: 0 !important;
  margin-left: 0;
  top: 0 !important;
  margin-top: 0;
}
.ui-datepicker-calendar > tbody > tr > td a {
  color: #000;
  font-weight: normal;
}
.ui-datepicker-calendar > thead > tr > th {
  font-family: "Arial", sans-serif;
  color: #1e76af !important;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  width: 24px;
  height: 24px;
  color: #fff;
  font-weight: 600;
  line-height: 24px;
  margin: 0 auto;
  border: 0px;
  background: #1e76af;
  border-radius: 50%;
}
.ui-datepicker td span,
.ui-datepicker td a {
  padding: 0;
}
.ui-datepicker td {
  padding: 0;
}
.ui-datepicker th {
  padding: 0;
  width: 30px;
  height: 30px;
}
.ui-datepicker table {
  margin: 0;
}

.ui-datepicker .ui-datepicker-prev {
  left: -3px !important;
  top: 7px !important;
}
.ui-datepicker .ui-datepicker-next {
  right: -3px !important;
  top: 7px !important;
}
