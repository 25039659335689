#modoo-exposure-main {
  & .body-container {
    & .content-wrapper {
      & .modoo-exposure-main-wrapper {
        & .page-contents {
          & .roundBx {
            & .topSearch {
              & .lDiv {
                width: fit-content;
                & ul {
                  width: fit-content;
                }
                & li {
                  display: flex;
                  width: fit-content !important;
                  height: 56px;
                  & .period {
                    display: flex;
                    width: fit-content !important;
                    & .date-picker {
                      margin-right: 16px;
                      width: 180px;
                    }
                  }
                  & .tt {
                    line-height: 56px;
                  }
                  & .swBtn {
                    margin: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
