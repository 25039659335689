#modoo-exhibition {
  & .body-container {
    & .content-wrapper {
      & .modoo-exhibition-wrapper {
        & .page-contents {
          & .roundBx {
            // 상단 검색 영역
            & .topSearch {
              display: flex;
              & .lDiv {
                & ul {
                  padding: 0;
                  & li {
                    display: flex;
                    // datepicker 영역
                    & .tt {
                      line-height: 37px;
                    }
                    & .period {
                      min-width: 340px;
                      & .date-picker {
                        float: left;
                        margin-right: 20px;
                        & .MuiInputBase-root {
                          padding: 0;
                          max-height: 40px;
                          max-width: 150px;
                        }
                      }
                      & .range-text {
                        position: relative;
                        right: 95px;
                        top: 20px;
                        font-size: large;
                      }
                    }
                  }
                }
              }
              & .fDiv {
                margin: 0;
                & ul {
                  padding: 0;
                  & li {
                    & .title {
                      min-width: 120px;
                      display: inline-block;
                      & input {
                        min-height: 40px;
                      }
                    }
                    // 검색버튼
                    & .swBtn {
                      margin-left: 15px;
                      min-height: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
