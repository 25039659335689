#pub-press-release {
  & .pub-press-release-wrapper {
    & .topSearch {
      & .lDiv {
        & ul {
          padding: 0;
          & li {
            display: flex;
          }
          & li:nth-of-type(1) {
            & div {
              min-width: 80px;
              max-height: 40px;
            }
          }
          & li:nth-of-type(2) {
            & input {
              min-height: 40px;
            }
          }
          & li:nth-of-type(3) {
            & button {
              margin-top: 0;
              min-height: 40px;
            }
          }
        }
      }
    }
    & .tbList {
      & .MuiPaper-root {
        & .MuiTableContainer-root {
          & .MuiTable-root {
            & .MuiTableHead-root {
              & .MuiTableRow-root {
                & .MuiTableCell-root:nth-of-type(1) {
                  max-width: 80px;
                }
                // & .MuiTableCell-root:nth-of-type(2) {
                //   width: 10%;
                // }
                & .MuiTableCell-root:nth-of-type(3) {
                  min-width: 150px;
                }
                // & .MuiTableCell-root:nth-of-type(4) {
                //   width: 10%;
                // }
                // & .MuiTableCell-root:nth-of-type(5) {
                //   width: 10%;
                // }
                // & .MuiTableCell-root:nth-of-type(6) {
                //   width: 10%;
                // }
              }
            }
            & .MuiTableBody-root {
              & .MuiTableRow-root {
                & .MuiTableCell-root:nth-of-type(1) {
                  max-width: 80px;
                }
                // & .MuiTableCell-root:nth-of-type(2) {
                //   width: 10%;
                // }
                & .MuiTableCell-root:nth-of-type(3) {
                  min-width: 150px;
                }
                // & .MuiTableCell-root:nth-of-type(4) {
                //   width: 10%;
                // }
                // & .MuiTableCell-root:nth-of-type(5) {
                //   width: 10%;
                // }
                // & .MuiTableCell-root:nth-of-type(6) {
                //   width: 10%;
                // }
              }
            }
          }
        }
      }
    }
    & .MuiPagination-root {
      margin-top: 10px;
      & .MuiPagination-ul {
        & li:first-of-type {
          margin-left: auto;
        }
        & li:last-of-type {
          margin-right: auto;
        }
      }
    }
  }
}
