#modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & .layerPop {
    & .lyInner {
      & .lyBody {
        & .lySample {
          & div {
            & .LazyLoad {
              & img {
                max-height: 500px;
              }
            }
          }
        }
      }
    }
  }
}
