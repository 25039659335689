#store-search-return-out {
  .search-container {
    display: flex;
    margin-bottom: 10px;
    & ul {
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      & li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        & .title-box {
          margin-right: 10px;
          padding-bottom: 5px;
        }
      }
      & li:nth-child(1) {
        width: 40%;
        & .period {
          width: 333px;
          display: flex;
          margin-left: 87px;
          & div {
            display: flex;
            align-items: center;
          }
          & .range-text {
            margin: 0 10px;
          }
          & .MuiFormControl-root {
            & .MuiInputBase-root {
              width: 100%;
              height: 32px;
              padding-left: 13px;
              padding-bottom: 2px;
              & .MuiInputBase-input {
                position: relative;
                padding: 0;
              }
            }
          }
        }
      }
      & li:nth-child(2) {
        width: 40%;
        & .state-box {
          width: 162px;
          & .select-options {
            height: 32px;
            width: 100%;
            text-align: left;
          }
        }
        & .req-type-box {
          width: 162px;
          margin-left: 10px;
          & .select-options {
            height: 32px;
            width: 100%;
            text-align: left;
          }
        }
      }
      & li:nth-child(3) {
        width: 50%;
        & .search-type-box {
          width: 131px;
          margin-right: 10px;
          & .select-options {
            height: 32px;
            width: 100%;
            text-align: left;
          }
        }
        & .input-box {
          width: 335px;
          margin-right: 10px;
          & input {
            height: 32px;
            width: 100%;
          }
        }
        & .search-button-box {
          & button {
            width: 70px;
            font-size: 16px;
            color: #333;
            line-height: 20px;
            height: 32px;
            border: 1px solid black;
            border-radius: 20px;
          }
          & button:hover {
            color: white;
            background-color: #333;
            transition: 0.5s;
          }
        }
      }
      & li:nth-child(4) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        & .btn-box {
          & button {
            width: 70px;
            height: 34px;
            border-radius: 5px;
            font-size: 14px;
          }
          & .approval-btn {
            border: 1px solid #3091ff;
          }
          & .approval-btn:hover {
            background-color: #3091ff;
            color: white;
            transition: 0.5s;
          }
          & .reject-btn {
            border: 1px solid #ff1a1a;
            margin-left: 10px;
          }
          & .reject-btn:hover {
            background-color: #ff1a1a;
            color: white;
            transition: 0.5s;
          }
        }
      }
    }
  }
  & .table-container {
    & .MuiPaper-root {
      box-shadow: none !important;
      & .MuiTableContainer-root {
        & .MuiBox-root {
          box-shadow: none;
          height: 100%;
          display: flex;
          align-items: center;
        }
        & table thead tr th.MuiTableCell-root {
          border-top: 2px #00182e solid;
          background-color: #fff;
          border-bottom: 1px solid #888888;
          box-shadow: none;
        }
      }
      & .MuiInputBase-root {
        border: 1px solid black;
        border-radius: 3px;
        background-color: white;
        padding-left: 5px;
      }
      & .MuiTableBody-root {
        & .MuiTableRow-root {
          & td:nth-of-type(1) {
            max-width: 10px !important;
            & span {
              margin: auto;
            }
          }
          & .MuiTableCell-body {
            font-weight: 500;
          }
        }
      }
    }
  }
}
