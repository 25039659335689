#modoo-mall-settlement {
  .search-container {
    display: flex;
    margin-bottom: 10px;
    & ul {
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      & li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        & .title-box {
          margin-right: 10px;
          padding-bottom: 5px;
        }
      }
      & li:nth-child(1) {
        width: 40%;
        & .title-box {
          margin-right: 40px;
        }
        & .period {
          width: 120px;
          display: flex;
          & div {
            display: flex;
            align-items: center;
          }
          & .range-text {
            margin: 0 10px;
          }
          & .MuiFormControl-root {
            & .MuiInputBase-root {
              width: 100%;
              height: 32px;
              padding-left: 13px;
              padding-bottom: 2px;
              & .MuiInputBase-input {
                position: relative;
                padding: 0;
              }
            }
          }
        }
      }
      & li:nth-child(2) {
        width: 40%;
        & .title-box {
          margin-right: 40px;
        }
        & .select-box {
          width: 250px;
          margin-right: 10px;
          & .select-options {
            height: 32px;
            width: 100%;
            text-align: left;
          }
        }
        & .search-button-box {
          & button {
            width: 70px;
            font-size: 16px;
            color: #333;
            line-height: 20px;
            height: 32px;
            border: 1px solid black;
            border-radius: 20px;
          }
          & button:hover {
            color: white;
            background-color: #333;
            transition: 0.5s;
          }
        }
      }
      & li:nth-child(3) {
        width: 40%;
        & .title-box {
          margin-right: 25px;
        }
        & .input-box {
          margin-right: 10px;
          & input {
            height: 32px;
            width: 250px;
          }
        }
        & .search-button-box {
          & button {
            width: 70px;
            font-size: 16px;
            color: #333;
            line-height: 20px;
            height: 32px;
            border: 1px solid black;
            border-radius: 20px;
          }
          & button:hover {
            color: white;
            background-color: #333;
            transition: 0.5s;
          }
        }
      }
      & li:nth-child(4) {
        justify-content: end;
        margin-bottom: 0;
        & .excel-btn {
          width: 180px;
          height: 35px;
          border: 1px solid #3091ff;
          border-radius: 20px;
          color: black;
          font-size: 16px;
          text-align: center;
        }
        & .excel-btn:hover {
          background-color: #3091ff;
          color: white;
          transition: 0.5s;
        }
      }
    }
  }
  & .table-container {
    & .MuiPaper-root {
      box-shadow: none !important;
      & .MuiTableContainer-root {
        & .MuiBox-root {
          box-shadow: none;
          height: 100%;
          display: flex;
          align-items: center;
        }
        & table thead tr th.MuiTableCell-root {
          border-top: 2px #00182e solid;
          background-color: #fff;
          border-bottom: 1px solid #888888;
          box-shadow: none;
        }
        & tbody {
          & tr {
            & td:nth-child(5) {
              padding-top: 0;
              padding-bottom: 0;
              & div {
                & input {
                  height: 27px;
                  text-align: right;
                  border: 1.5px solid #aaa;
                  border-radius: 5px;
                  padding: 10px;
                }
                & input:focus {
                  outline: none !important;
                  border-color: #3091ff;
                  box-shadow: 0 0 10px #70b0f9;
                }
              }
            }
          }
        }
        & tfoot {
          & tr {
            & td {
              border: none;
              padding: 0;
            }
          }
        }
      }
      & .MuiInputBase-root {
        border: 1px solid black;
        border-radius: 3px;
        background-color: white;
        padding-left: 5px;
      }
    }
  }
}
