#pub-notice {
  & .content-header-container {
    display: flex;
    justify-content: space-between;
    & .search-filter-container {
      display: flex;
      height: 35px;
      margin-bottom: 10px;
      & .search-filter {
        display: flex;
        margin-right: 30px;
        & .search-filter-title {
          margin-right: 15px;
          line-height: 35px;
          font-size: 14px;
          font-weight: 600;
        }
        & .search-filter-component {
          display: flex;
          & .date-picker.range {
            display: flex;
            & .date-picker {
              width: 150px;
            }
            & .range-text {
              width: 25px;
              line-height: 35px;
            }
          }
          & .input-field {
            height: 35px;
            width: 250px;
          }
          & input {
            height: 35px;
            padding-top: 0px;
            padding-bottom: 0px;
            line-height: 35px;
          }
          & .select-options {
            text-align: left;
            width: 120px;
          }
        }
      }
      & .search-button {
        width: 92px;
        height: 35px;
        background: #000;
        text-align: center;
        border-radius: 50px;
        box-shadow: none;
        span {
          font-size: 16px;
          color: #fff;
          line-height: 35px;
        }
      }
    }
    // 버튼 영역
    & .button-container {
      & .newAppBtn {
        width: 180px;
        height: 35px;
        font-size: 16px;
        color: #000;
        background: #fff;
        text-align: center;
        line-height: 35px;
        border: 1px #3091ff solid;
        border-radius: 20px;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
      .newAppBtn:hover {
        background: #3091ff;
        color: #fff;
      }
    }
  }

  // 테이블 영역
  & .content-table-container {
    .MuiPaper-root {
      box-shadow: none !important;
      & .MuiTableContainer-root {
        & .MuiBox-root {
          box-shadow: none;
        }
        & table thead tr th.MuiTableCell-root {
          border-top: 2px #00182e solid;
          border-bottom: 1px solid #707070;
          background: #f9f9f9;
        }
      }
      & .MuiInputBase-root {
        border: 1px solid black;
        border-radius: 3px;
        background-color: white;
        padding-left: 5px;
      }

      & .MuiTableCell-body {
        font-weight: 500;
        .swBtn {
          width: auto;
          height: 22px;
          color: #333;
          background: #fff;
          text-align: center;
          font-size: 12px;
          border: 1px #111 solid;
          border-radius: 10px;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
        .swBtn:hover {
          color: #fff;
          background: #111;
        }
        & .delBtn {
          justify-content: center;
          text-align: center;
          width: 21px;
          height: 24px;
          background: url("../../../imgs/btn_select_list_del.png") top no-repeat;
        }
      }
    }
  }
}
