#mall-search-lifeJoin {
  & .content-wrapper {
    > .mall-lifejoin-wrap {
      > .page-contents {
        > .roundBx {
          height: calc(100vh - 101px - 70px - 48px - 34px);
          margin-bottom: 0;
          > .topSearch {
            display: flex;
            border-top: 2px #000 solid;
            align-items: center;
            border-bottom: 1px #ebebeb solid;
            margin-bottom: 35px;
            padding: 10px 0;
            > div {
              > ul {
                display: flex;
                > li {
                  display: flex;
                  align-items: center;
                }
              }
            }
            > .lDiv {
              & ul {
                padding: 0;
                & li {
                  & .select-options {
                    width: 100px;
                    height: 38px;
                    font-size: 14px;
                  }
                  & .wBase {
                    height: 38px;
                    width: 280px;
                  }
                }
              }
            }
            > .fDiv {
              margin: 0;
            }
            > .swBtn {
              min-height: 30px;
            }
          }
          > .tbList {
            & .grade-change-table {
              & tbody {
                & tr {
                  & .left {
                    > div {
                      & strong {
                        height: 32px;
                        display: flex;
                        align-items: center;
                        & .MuiInputBase-root {
                          width: 120px;
                          height: 32px;
                        }
                        & .swBtn {
                          min-height: 32px;
                        }
                      }
                    }
                  }
                }
              }
            }
            & .MuiPaper-root {
              height: calc(100vh - 510px);
              max-height: calc(100vh - 510px);
              overflow: auto;
              box-shadow: none !important;
              & .MuiTableContainer-root {
                height: 100%;
                & .MuiTable-root {
                  height: 100%;
                  display: table;
                  & .MuiTableHead-root {
                    display: table-header-group;
                    & .MuiTableRow-root {
                      // & th:nth-of-type(1) {
                      //   max-width: 10px;
                      // }
                    }
                  }
                  & .MuiTableBody-root {
                    display: table-row-group;
                    & .MuiTableRow-root {
                      // & td:nth-of-type(1) {
                      //   max-width: 10px !important;
                      //   & span {
                      //     margin: auto;
                      //   }
                      // }
                    }
                  }
                  & .MuiTableFooter-root {
                    display: table-footer-group;
                    height: 40px;
                    & .MuiTableCell-root {
                      padding: 8px;
                      line-height: 23px;
                      font-size: 14px;
                      font-weight: 600;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
