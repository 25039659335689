& .story-container {
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  width: 1400px;

  & .body-container {
    margin-top: 32px;
    display: flex;
    & .left {
      display: flex;
      flex-direction: column;
      width: 830px;
      height: 634px;
      margin-right: 40px;
      cursor: pointer;
      & .img img {
        width: 830px;
        border-radius: 16px 16px 0 0;
        object-fit: cover;
      }
      & .text {
        height: 192px;
        border-radius: 0 0 16px 16px;
        padding: 32px;
        color: white;
        text-align: left;
        & .story-title {
          font-size: 24px;
          font-weight: bold;
        }
        & .story-comment {
          margin-top: 12px;
          font-size: 15px;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }
    & .right {
      display: flex;
      flex-direction: column;
      width: 530px;
      & .title-container {
        display: flex;
        flex-direction: column;
        & .title {
          font-size: 20px;
          text-align: left;
        }
      }
      & .recommended-book-list {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        & .recommended-book {
          margin-bottom: 30px;
          display: flex;
          cursor: pointer;
          border-bottom: 1px solid #e8e8e8;
          & .img {
            width: 100px;
            margin-right: 24px;
            margin-bottom: 30px;
            & img {
              width: 100px;
              box-shadow: 0px 5px 10px #00000033;
              border-radius: 8px;
            }
          }
          & .book-info {
            display: flex;
            flex-direction: column;
            text-align: left;
            & .class {
              padding: 4px 12px;
              border: 1px solid #eeeeee;
              border-radius: 4px;
              width: fit-content;
              font-size: 12px;
              line-height: 17px;
            }
            & .title {
              margin-top: 16px;
              font-size: 18px;
              line-height: 32px;
              font-weight: bold;
            }
            & .comment {
              margin-top: 8px;
              font-size: 14px;
              line-height: 28px;
              width: 400px;
            }
          }
        }
      }
    }
  }
}
