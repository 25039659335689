@import "./preview.scss";
#mall-main {
  & .body-container {
    & .content-wrapper {
      & .mall-main-wrapper {
        & .page-contents {
          & .roundBx {
            & .topSearch {
              & .lDiv {
                & ul {
                  padding: 0;
                  & li {
                    // datepicker 영역
                    & .period {
                      min-width: 340px;
                      & .date-picker {
                        float: left;
                        margin-right: 20px;
                        & .MuiInputBase-root {
                          padding: 0;
                          max-height: 40px;
                          max-width: 150px;
                        }
                      }
                      & .range-text {
                        position: relative;
                        right: 95px;
                        top: 20px;
                        font-size: large;
                      }
                    }
                  }
                  // 검색버튼
                  & .swBtn {
                    min-height: 30px;
                  }
                }
              }
            }
            & .httNode {
              text-align: left;
            }
            & .districtArea {
              & .districtNode {
                text-align: left;
                & .switch {
                  left: 25px;
                }
              }
              & .bSearch {
                text-align: left;
              }
              & .selectList {
                & .seLx {
                  & .seDx {
                    & div {
                      & .seBx {
                        & .st {
                          top: 15px;
                        }
                        & .data {
                          & p {
                            text-align: left;
                          }
                        }
                      }
                    }
                    & .item1 {
                      width: 45%;
                    }
                    & .item2 {
                      width: 20%;
                    }
                    & .item3 {
                      width: 15%;
                      & .seBx {
                        height: 44.4px;
                        line-height: 40px;
                        & .data {
                          & .orderList {
                            width: 70px;
                          }
                        }
                      }
                    }
                    & .item4 {
                      width: 20%;
                      & .seBx {
                        height: 44.4px;
                        line-height: 44.4px;
                        & .data {
                          & .colorPicker {
                            width: 60px;
                          }
                        }
                      }
                    }
                  }
                  & .delBtn {
                    top: 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
