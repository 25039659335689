#book-recommended-institutions {
  & .page-contents {
    width: 100%;
    & .wttNode {
      text-align: left;
    }
    & .roundBx {
      min-width: 1250px;
      & .ul-none {
        padding: 0;
        min-width: 600px;
      }

      & .blackBtn {
        min-width: 200px;
        color: white;
      }
      & .rdivide {
        width: 100%;
        padding: 0;
      }
      & .MuiFormControl-root {
        min-width: 80px;
        & .MuiInputBase-root {
          width: 120px;
          height: 32px;
        }
      }

      & .MuiOutlinedInput-notchedOutline {
        border-color: black;
      }
      & .MuiInputBase-input {
        background-color: white;
      }
      & MuiSelect-select {
        width: 153px;
        height: 20px;
      }
      & .swBtn {
        min-width: 60px;
      }
      & .swBtn:hover {
        background-color: black;
        color: white;
      }
    }
  }
}
