#modoo-member {
  & .content-wrapper {
    & .modoo-member-wrapper {
      & .page-contents {
        & .roundBx {
          & .topSearch {
            max-width: 400px;
            border-top: 2px #000 solid;
            border-bottom: 1px #ebebeb solid;
            margin-bottom: 35px;
            padding-bottom: 20px;
            & .lDiv {
              margin-top: 15px;
              & ul {
                padding: 0;
                & li {
                  & .select-options {
                    width: 100px;
                    height: 32px;
                    font-size: 14px;
                  }
                  & .wBase {
                    width: 280px;
                  }
                }
              }
            }
            & .fDiv {
              display: flex;
              & ul {
                margin-top: 5px;
                padding: 0;
                & .full {
                  display: flex;
                  min-height: 40px;
                  & .tt {
                    text-align: left;
                    margin-right: 30px;
                    padding-top: 10px;
                  }
                  & .classNameification {
                    & ul {
                      padding: 0;
                      list-style: none;
                      margin-top: 5px;
                    }
                  }
                }
              }
            }
            & .swBtn {
              margin-top: 5px;
              float: right;
              min-height: 30px;
            }
          }
          & .tbList {
            & .grade-change-table {
              & tbody {
                & tr {
                  & .left {
                    & div {
                      & strong {
                        & .MuiInputBase-root {
                          width: 120px;
                          height: 32px;
                        }
                        & .swBtn {
                          min-height: 30px;
                        }
                      }
                    }
                  }
                }
              }
            }
            & .MuiPaper-root {
              & .MuiTableContainer-root {
                & .MuiTable-root {
                  & .MuiTableHead-root {
                    & .MuiTableRow-root {
                      & th:nth-of-type(1) {
                        max-width: 10px;
                      }
                    }
                  }
                  & .MuiTableBody-root {
                    & .MuiTableRow-root {
                      & td:nth-of-type(1) {
                        max-width: 10px !important;
                        & span {
                          margin: auto;
                        }
                      }
                      & .MuiTableCell-body {
                        & .ellipsis {
                          width: 100%;
                          height: 28px;
                          line-height: 28px;
                        }
                      }
                    }
                  }
                }
              }
              & .MuiTableFooter-root {
                height: 40.67px;
                & .MuiTableCell-root {
                  padding: 8px;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 23.656px;
                  & span {
                    margin-left: -60%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
