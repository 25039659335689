#modoo-day-sales-all {
  & .search-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    & ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      & li:nth-child(1) {
        display: flex;
        align-items: center;
        margin-right: 10px;
        & .title-box {
          padding-bottom: 5px;
        }
      }
      & li:nth-child(2) {
        width: 55%;
        margin-right: 10px;
        & .period {
          display: flex;
          & div {
            display: flex;
            align-items: center;
          }
          & .range-text {
            margin: 0 10px;
          }
          & .MuiFormControl-root {
            & .MuiInputBase-root {
              width: 100%;
              height: 33px;
              padding-left: 13px;
              padding-bottom: 2px;
              & .MuiInputBase-input {
                position: relative;
                padding: 0;
              }
            }
          }
        }
      }
      & li:nth-child(3) {
        display: flex;
        align-items: center;
        & button {
          width: 70px;
          font-size: 16px;
          color: #333;
          line-height: 20px;
          height: 32px;
          border: 1px solid black;
          border-radius: 20px;
        }
        & button:hover {
          color: white;
          background-color: #333;
          transition: 0.5s;
        }
      }
    }
  }
  & .excel-box {
    & .excel-btn {
      width: 180px;
      height: 35px;
      border: 1px solid #3091ff;
      border-radius: 20px;
      font-size: 16px;
      cursor: pointer;
    }
    & .excel-btn:hover {
      background-color: #3091ff;
      color: white;
      transition: 0.5s;
    }
  }
  & .table-container {
    & .MuiPaper-root {
      box-shadow: none !important;
      & .MuiTableContainer-root {
        & .MuiBox-root {
          box-shadow: none;
        }
        & table thead tr th.MuiTableCell-root {
          border-top: 2px #00182e solid;
          background-color: #fff;
          border-bottom: 1px solid #888888;
          box-shadow: none;
        }
        & tbody {
          & tr {
            & td {
              padding: 0;
            }
          }
        }
        & tfoot {
          & tr {
            & td {
              border: none;
              padding: 0;
            }
            & td:last-child {
              padding: 0;
            }
          }
        }
      }
      & .MuiInputBase-root {
        border: 1px solid black;
        border-radius: 3px;
        background-color: white;
        padding-left: 5px;
      }
    }
  }
}
