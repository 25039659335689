.layerPop.base.preview {
  width: 1520px;
  height: 90vh;
  & .lyPreview {
    max-height: calc(90vh - 160px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
  & .popBtnArea {
    margin-top: 20px;
  }
  & .lyInner {
    height: 90vh;
    padding: 0 60px;
    & .title-container {
      display: flex;
      width: 1400px;
      margin: 0 auto;
      & .title {
        font-size: 24px;
        font-weight: bold;
        & span {
          color: #b7916b;
        }
      }
      & button {
        margin-left: auto;
      }
    }
    @import "./StoryBanner.scss";
    @import "./Channel.scss";
    @import "./Notice.scss";
    @import "./RecommendedBook.scss";
  }
}

#recommended-book-picker-presenter {
  margin-top: 40px;
  display: flex;
  & .left {
    display: flex;
    flex-direction: column;
    & .class {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      padding: 4px 12px;
      width: fit-content;
    }
    & .title {
      margin-top: 32px;
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
      text-align: left;
    }
    & .book-info {
      display: flex;
      margin-top: 8px;
      & .info {
        color: #777777;
        font-size: 12px;
        line-height: 17px;
      }
      & .divider {
        height: 8px;
        width: 1px;
        border-left: 1px solid #777777;
        opacity: 0.5;
        margin: auto 8px;
      }
    }
    & .other-recommendation {
      display: flex;
      flex-direction: column;
      & .other-recommendation-title {
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        margin-top: 120px;
      }
      & .recommendation-list {
        display: flex;
        margin-top: 16px;
        margin-bottom: 90px;
        & .recommendation-book {
          cursor: pointer;
          width: 100px;
          opacity: 0.5;
          margin-right: 16px;
          & img {
            width: 100px;
            box-shadow: 0px 5px 10px #00000029;
            border-radius: 8px;
          }
        }
      }
    }
  }
  & .img {
    width: 260px;
    margin-left: 240px;
    cursor: pointer;
    & img {
      width: 260px;
      box-shadow: 0px 5px 20px #00000033;
      border-radius: 8px;
    }
  }
  & .right {
    margin-left: 240px;
    width: 316px;
    & .one-line-comment {
      font-size: 20px;
      line-height: 38px;
      text-align: left;
      font-weight: bold;
    }
    & .comment {
      text-align: left;
      font-size: 14px;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      margin-top: 24px;
    }
    & #price-container {
      margin-top: 24px;
    }
    & .button-container {
      display: flex;
      margin-top: 80px;
      & button {
        width: 155px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 20px;
        margin-right: 16px;
        border: 1px solid #b7916b;
        color: #b7916b;
        & .button-text {
          display: flex;
          & .image {
            margin-left: auto;
            margin-top: 3px;
          }
        }
        &:nth-of-type(2) {
          background: #b7916b 0% 0% no-repeat padding-box;
          color: white;
        }
      }
    }
  }
}

#six-book-swiper-presenter {
  & .new-book-body {
    margin-top: 28px;
    width: 1400px;
    min-height: 488px;
    & .swiper-slide {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      & .book-image {
        min-height: 200px;
        cursor: pointer;
        & img {
          width: 200px;
          height: auto !important;
          border-radius: 10px;
          box-shadow: 0px 5px 10px #00000029;
        }
      }
      & .book-title {
        margin-top: 16px;
        font-size: 16px;
        font-weight: bold;
      }
      & .book-author-publisher {
        width: 200px;
        margin-top: 8px;
        font-size: 12px;
        color: #777777;
      }
      & .price-container {
        display: flex;
        width: auto;
        margin-top: 20px;
        & .sale-percentage {
          margin: auto;
          margin-right: 0;
          width: 26px;
          height: 17px;
          padding: 4px 8px;
          color: white;
          border-radius: 4px;
          background-color: #1e76af;
          font-size: 12px;
        }
        & .price {
          margin-left: 12px;
          font-size: 20px;
          font-weight: bold;
        }
        & .price-unit {
          font-size: 14px;
          margin: auto auto 3px 2px;
        }
      }
    }
    & .swiper-button-prev {
      cursor: pointer;
      &::after {
        content: none;
      }
    }
    & .swiper-button-next {
      cursor: pointer;
      &::after {
        content: none;
      }
    }
    & .swiper-pagination {
      position: relative;
      margin-top: 40px;
      & .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        &.swiper-pagination-bullet-active {
          width: 20px;
          border-radius: 10px;
          background-color: black;
        }
      }
    }
  }
  & .button-container {
    display: flex;
    flex-direction: row;
    & .prev-button {
      margin-left: -60px;
      margin-top: -300px;
      background-color: #efebe5;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    & .next-button {
      margin-left: auto;
      margin-right: -60px;
      margin-top: -300px;
      background-color: #efebe5;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
}

#book-info-container {
  display: flex;
  flex-direction: column;
  width: 420px;
  & .class {
    padding: 4px 12px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    width: fit-content;
    font-size: 12px;
  }
  & .book-info-title-container {
    display: flex;
    margin-top: 20px;
    & .book-info-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 32px;
      text-align: left;
      width: fit-content;
      cursor: pointer;
    }
    & .more-button {
      width: 24px;
      height: 24px;
      margin: 4px 0 0 16px;
      cursor: pointer;
    }
  }
  & .info-container {
    margin-top: 8px;
    display: flex;
    font-size: 12px;
    color: #777777;
    & div {
      height: 8px;
      width: 1px;
      margin: auto 8px;
      border-left: 1px solid #777777;
    }
  }
  & .comment {
    margin-top: 24px;
    font-size: 14px;
    line-height: 28px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  & #price-container {
    margin-top: 24px;
  }
  & .button-container {
    display: flex;
    margin-top: 40px;
    & button {
      width: 156px;
      height: 56px;
      border-radius: 8px;
      padding: 16px 20px;
      margin-right: 16px;
      border: 1px solid #b7916b;
      color: #b7916b;
      & .button-text {
        display: flex;
        & .image {
          margin-left: auto;
          margin-top: 3px;
        }
      }
      &:nth-of-type(2) {
        background: #b7916b 0% 0% no-repeat padding-box;
        color: white;
      }
    }
  }
}

#price-container {
  display: flex;
  & .sale-percent {
    background: #b7916b 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 4px 8px;
    color: white;
    font-size: 12px;
    height: fit-content;
    margin-top: 8px;
  }
  & .price-after {
    margin-left: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    & span {
      font-size: 28px;
      margin-right: 4px;
    }
  }
  & .price-before {
    margin-top: 12px;
    margin-left: 12px;
    color: #777777;
    text-decoration: line-through;
  }
}
