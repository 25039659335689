#store-new-book-list {
  & .content-wrapper {
    width: 100%;
    & .fDiv {
      width: 100%;
      & li {
        margin-right: 10px;
      }
      & .MuiInputBase-root {
        min-width: 150px;
        height: 32px;
        & .MuiSvgIcon-root {
          padding-top: 3px;
        }
      }
    }
    & .lDiv {
      & .MuiInputBase-root {
        min-width: 192px;
        height: 32px;
      }
      & .swBtn {
        margin: 0;
        min-height: 32px;
      }
    }

    & .rDiv {
      & .MuiLink-root {
        min-width: 150px;
        min-height: 32px;
      }
      & .MuiTypography-root:hover {
        color: white;
      }
      & .newAppBtn:hover {
        color: white;
      }
    }

    // 일자 선택 영역
    & .search-filter-date {
      display: flex;
      margin-right: 20px;
      // 데이터 영역
      & .search-filter-component {
        display: flex;
        height: 32px;
        & .date-picker {
          width: 150px;
          height: 32px;
        }
        // ~
        & .range-text {
          width: 10px;
          margin: 0 10px;
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
        }
        & .MuiInputBase-root {
          height: 32px;
          & .MuiInputBase-input {
            line-height: 32px;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
