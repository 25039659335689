#mall-marketing-register {
  & .body-container {
    & .content-wrapper {
      & .mall-marketing-wrapper {
        & .page-contents {
          & .roundBx {
            & .registList {
              & .reBx {
                & .ab {
                  top: 0px;
                }
                & .data {
                  & .fileList {
                    & ul {
                      padding: 0;
                    }
                  }
                  & textarea {
                    resize: vertical;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
