#mall-story-register {
  & .body-container {
    & .content-wrapper {
      & .mall-story-register-wrapper {
        & .page-contents {
          & .roundBx {
            & .registList {
              & .reBx {
                & .ab {
                  top: 0px;
                }
                & .data {
                  & .iList {
                    & ul {
                      padding: 0;
                    }
                  }
                  & .colorList {
                    & ul {
                      padding: 0;
                      & li {
                        & .colorPicker {
                          & label {
                            cursor: pointer;
                          }
                        }
                      }
                    }
                  }
                  & .templates {
                    & ul {
                      padding: 0;
                      & li {
                        padding: 0;
                        & .fmRad-i {
                          & .img {
                            & div {
                              margin: 0 auto;
                              width: 117px;
                              cursor: pointer;
                            }
                          }
                        }
                        & .fmRad-i::before {
                          display: none;
                        }
                        & .fmRad-i > .fmRad:checked ~ .img > div {
                          border: 1px solid #ff0000;
                        }
                      }
                    }
                  }
                  & textarea {
                    resize: vertical;
                  }
                  & .bSearch {
                    text-align: left;
                  }
                  & .httNode {
                    text-align: left;
                  }
                  & .selectList {
                    & .seLx {
                      & .seDx {
                        & div {
                          & .seBx {
                            & .st {
                              top: 15px;
                            }
                            & .data {
                              & p {
                                text-align: left;
                              }
                            }
                          }
                        }
                        & .item1 {
                          width: 45%;
                        }
                        & .item2 {
                          width: 20%;
                        }
                        & .item3 {
                          width: 15%;
                        }
                        & .item4 {
                          width: 20%;
                        }
                      }
                      & .delBtn {
                        top: 35px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
