#book-modify-request {
  & .fAddTxt {
    text-align: left;
  }
  & .httNode {
    text-align: left;
    padding-bottom: 14px;
  }
  & .btnCArea {
    & .aprroveBtn {
      width: 84px;
      background: #232323;
      border-radius: 50px;
      font-size: 14px;
      color: #fff;
      font-weight: 600;
      line-height: 30px;
      border: 1px solid #232323;
    }
    & .holdBtn {
      width: 84px;
      font-size: 14px;
      border-radius: 50px;
      color: #232323;
      font-weight: 600;
      line-height: 30px;
      border: 1px #232323 solid;
    }
  }
  & .content-table-container {
    max-height: 500px;

    & .MuiPaper-root {
      box-shadow: none !important;
      & .MuiTableContainer-root {
        & .MuiBox-root {
          box-shadow: none;
        }
        & table thead tr th.MuiTableCell-root {
          border-top: 2px #00182e solid;
          background-color: #fff;
          border-bottom: 1px solid #888888;
          box-shadow: none;
        }
        & tfoot {
          background-color: #f7f7f7;
        }
        & .modify-btn {
          height: 22px;
          border: 1px #111 solid;
          background: #fff;
          border-radius: 10px;
          color: #111;
        }
      }
      & .MuiInputBase-root {
        border: 1px solid black;
        border-radius: 3px;
        background-color: white;
        padding-left: 5px;
      }
    }
  }
  & .before-container {
    & .MuiTable-root {
      & .MuiTableRow-root {
        & th:first-child {
          max-width: 10px;
        }
        & td:first-child {
          max-width: 10px;
        }
      }
    }
  }
}
