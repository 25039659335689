#mall-marketing {
  & .body-container {
    & .content-wrapper {
      & .mall-marketing-wrapper {
        & .page-contents {
          & .roundBx {
            & .topSearch {
              & .lDiv {
                & ul {
                  padding: 0;
                  & li {
                    & .swBtn {
                      min-height: 30px;
                      margin-top: 2px;
                      margin-left: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
