#store-search-order {
  & .body-container {
    width: 100%;
    & .search-button {
      width: 70px;
      font-size: 16px;
      color: #333;
      line-height: 20px;
      height: 32px;
      border: 1px solid black;
      border-radius: 20px;
    }
    & .search-button:hover {
      color: white;
      background-color: #333;
      transition: 0.5s;
    }
    & .excel-btn {
      width: 150px;
      height: 35px;
      border: 1px solid #3091ff;
      border-radius: 20px;
      font-size: 16px;
      cursor: pointer;
    }
    & .excel-btn:hover {
      background-color: #3091ff;
      color: white;
      transition: 0.5s;
    }

    & .search-option-container {
      width: 100%;
      & .search-filter-search-titleArea {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        & div {
          padding-right: 10px;
        }
        & .search-filter-search-title {
          padding-bottom: 2px;
          margin-right: 14px;
        }
        & .search-filter-auto {
          & .MuiAutocomplete-root {
            width: 240px;
            & .MuiAutocomplete-popper {
              & ul {
                width: 500px;
              }
            }
            & .MuiFormControl-root {
              & .MuiInputBase-root {
                padding: 0;
                padding-left: 5px;
                height: 32px;
                & .MuiInputBase-input {
                  padding: 0;
                  padding-left: 5px;
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }
    & .search-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 25px;
      & .search-filter-container {
        display: flex;

        & .search-comp {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-right: 20px;
          & .search-filter-title {
            margin-right: 10px;
          }
        }
        & .search-date {
          & .date-component {
            display: flex;
            align-items: center;
            & .range-text {
              margin: 0 10px;
            }
            & .MuiInputBase-root {
              width: 150px;
              height: 32px;
              & MuiSelect-select {
                width: 150px;
                height: 32px;
              }
              & .MuiInputBase-input {
                padding: 0;
                padding-left: 8px;
              }
            }
          }
        }
        & .search-select-gubn {
          & .MuiInputBase-root {
            width: 120px;
            height: 32px;
            & .MuiSelect-select {
              padding: 0;
              padding-left: 5px;
              padding-right: 15px;
            }
          }
        }
        & .search-input-box {
          & .MuiAutocomplete-root {
            & .MuiFormControl-root {
              & .MuiInputBase-root {
                padding: 0;
              }
            }
          }
          & .MuiFormControl-root {
            min-width: 80px;
            & .MuiInputBase-root {
              width: 250px;
              height: 32px;
            }
          }
        }
      }
    }
    & .detail-table-container {
      & .detail-head-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        & .button-container {
          & .excel-btn {
            margin-left: 5px;
          }
          & button {
            width: 150px;
            height: 32px;
            border: 1px solid #3091ff;
            border-radius: 20px;
            font-size: 16px;
            cursor: pointer;
          }
          & button:hover {
            background-color: #3091ff;
            color: white;
            transition: 0.5s;
          }
        }
      }
    }
  }
}

#cancel-confirm-modal-container {
  & .body-container {
    width: 350px;
    height: 170px;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 10px;
    & .confirm-text {
      height: 110px;
      padding-top: 40px;
      font-size: 18px;
    }
    & .button-container {
      & button {
        width: 105px;
      }
      // display: flex;
      & .order-cancel-button {
        margin-right: 5px;
        border: 1px solid #3091ff;
        color: #3091ff;
        border-radius: 10px;
      }
      & .order-cancel-button:hover {
        background-color: #eee;
      }
      & .no-button {
        margin-left: 5px;
        border: 1px solid #3091ff;
        color: #3091ff;
        border-radius: 10px;
      }
      & .no-button:hover {
        background-color: #eee;
      }
    }
  }
}

.option-list {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
  cursor: pointer;
  & .option-list-item {
    padding: 0 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    height: 32px;
    line-height: 32px;
    display: flex;
    cursor: pointer;
    & div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:first-of-type {
        width: 120px;
      }
      &:nth-of-type(3) {
        width: 180px;
      }
      &:nth-of-type(5) {
        width: 80px;
        text-align: right;
      }
      &:nth-of-type(7) {
        width: 70px;
      }
      &:nth-of-type(9) {
        width: 120px;
      }
      &:nth-of-type(2),
      &:nth-of-type(4),
      &:nth-of-type(6),
      &:nth-of-type(8) {
        width: 20px;
        text-align: center;
      }
    }
  }
}

.MuiAutocomplete-popper {
  & .MuiAutocomplete-paper {
    & .MuiAutocomplete-listbox {
      & .Mui-focused {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}

.search-book-async-input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & input {
    max-width: 130px !important;
  }
}
