#sign-in {
  & .sign-in-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    & .sign-in-element-container {
      width: 452px;
      margin: 50px auto 0;
      overflow: hidden;
      & .sign-in-image-container {
        width: 100%;
        text-align: center;
        padding: 0 0 20px 0;
        overflow: hidden;
      }
      & .sign-in-form-container {
        width: 100%;
        overflow: hidden;
        & .title {
          font-size: 42px;
          color: #787878;
          line-height: 1.2;
          font-weight: 600;
          text-align: center;
          padding: 0 0 38px 0;
        }
        & .input-button-container {
          width: 100%;
          overflow: hidden;
          & .id-input {
            display: block;
            margin: 15px 0 0 0;
          }
          & .password-input {
            display: block;
            margin: 15px 0 0 0;
            & input {
              height: 56px;
              line-height: 56px;
              font-size: 16px;
              border-color: #cecece;
              border-radius: 5px;
              padding: 0 0 0 15px;
              border: 0;
            }
            & .MuiInputAdornment-root {
              & button {
                position: relative;
                left: 390px;
                bottom: 28px;
              }
            }
          }
          & .error-message {
            min-height: 30px;
          }
          & .sign-in-button {
            width: 30%;
            height: 50px;
            border-radius: 50px;
          }
        }
      }
    }
    & .sign-in-banner-container {
      width: 100%;
      max-height: 150px;
      margin: 40px 0 0 0;
      overflow: hidden;
      text-align: center;
      line-height: 0;
      & .banner-container {
        display: inline-block;
        margin: 0 10px;
      }
    }
  }
  & .sign-in-container::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1000%;
    height: 20px;
    background: #144e82;
    margin: 0 0 0 -500%;
  }
  & .sign-in-footer-container {
    width: 100%;
    text-align: center;
    padding: 22px 0;
    margin: 75px 0 0 0;
    border-top: 1px #e8e8e8 solid;
    overflow: hidden;
    & p {
      font-size: 12px;
      color: #333;
      line-height: 1.2;
      padding: 2px 0;
    }
  }
}
