#mall-search-lifeJoin-detail {
  & .body-container {
    & .content-wrapper {
      & .mall-life-join-detail {
        & .page-contents {
          & .roundBx {
            & .tbList {
              & table {
                & tr:hover {
                  background-color: unset;
                }
                & tr {
                  & td:hover {
                    background-color: rgba(235, 235, 235);
                  }
                }
              }
            }
            & .mallOrd30Day {
              & .MuiPaper-root {
                // height: calc(100vh - 510px);
                // max-height: calc(100vh - 510px);
                overflow: auto;
                box-shadow: none !important;
                & .MuiTableContainer-root {
                  outline: 1px solid red;
                  height: 100%;
                  & .MuiTable-root {
                    height: 100%;
                    display: table;
                    & .MuiTableHead-root {
                      display: table-header-group;
                      & .MuiTableRow-root {
                        & .MuiTableCell-root:nth-of-type(1) {
                          display: none;
                        }
                      }
                    }
                    & .MuiTableBody-root {
                      display: table-row-group;
                      & .MuiTableRow-root {
                        & .MuiTableCell-root:nth-of-type(1) {
                          display: none;
                        }
                        & .MuiTableCell-root {
                          & .MuiInputBase-root {
                            width: 100%;
                            & input {
                              width: 100%;
                            }
                          }
                        }
                      }
                    }
                    & .MuiTableFooter-root {
                      display: table-footer-group;
                      height: 40px;
                      & .MuiTableCell-root {
                        padding: 8px;
                        line-height: 23px;
                        font-size: 14px;
                        font-weight: 600;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
