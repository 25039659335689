.header-wrapper {
  position: relative;
  width: 100%;
  height: 101px;
  background: #f7f7f7;
  border-bottom: 1px #e8e8e8 solid;
  overflow: hidden;
  z-index: 50;
  & .header-container {
    position: relative;
    width: auto;
    margin: 0 20px;
    & .header-logo {
      position: absolute;
      left: 0;
      top: 42px;
      z-index: 110;
      cursor: pointer;
    }
    & .header-name-wrapper {
      position: absolute;
      right: 95px;
      top: 45px;
      & .header-name-button {
        display: inline-block;
        font-size: 12px;
        color: #000;
        line-height: 32px;
        padding: 0 30px 0 0;
        background: url("../../imgs/my_name_icon.png") right 50% no-repeat;
      }
    }
    & .header-button-wrapper {
      position: absolute;
      right: 3px;
      top: 45px;
      & .header-button {
        display: inline-block;
        font-size: 12px;
        color: #000;
        line-height: 32px;
        padding: 0 38px 0 0;
        background: url("../../imgs/logout_icon.png") right 50% no-repeat;
      }
    }
  }
}
.header-wrapper::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1000%;
  height: 20px;
  background: #144e82;
  margin: 0 0 0 -500%;
}
