#book-review-management {
  & .wttNode {
    text-align: left;
  }
  & .ul-none {
    padding: 0;
  }
  & .swBtn {
    min-width: 60px;
  }
  & .newAppBtn {
    font-size: 14px;
  }
  & .swBtn:hover {
    background-color: black;
    color: white;
  }
  & .newAppBtn:hover {
    background-color: #3091ff;
    color: white;
  }
}
