#store-search-forwarding {
  & .body-container {
    width: 100%;
    & .search-button {
      width: 70px;
      font-size: 16px;
      color: #333;
      line-height: 20px;
      height: 32px;
      border: 1px solid black;
      border-radius: 20px;
    }
    & .search-button:hover {
      color: white;
      background-color: #333;
      transition: 0.5s;
    }
    & .excel-btn {
      width: 150px;
      height: 35px;
      border: 1px solid #3091ff;
      border-radius: 20px;
      font-size: 16px;
      cursor: pointer;
    }
    & .excel-btn:hover {
      background-color: #3091ff;
      color: white;
      transition: 0.5s;
    }

    & .search-option-container {
      width: 100%;
      & .search-filter-search-titleArea {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        & div {
          padding-right: 10px;
        }
        & .search-filter-search-title {
          padding-bottom: 2px;
          margin-right: 14px;
        }
        & .search-filter-auto {
          & .MuiAutocomplete-root {
            width: 240px;
            & .MuiAutocomplete-popper {
              & ul {
                width: 500px;
              }
            }
            & .MuiFormControl-root {
              & .MuiInputBase-root {
                padding: 0;
                padding-left: 5px;
                height: 32px;
                & .MuiInputBase-input {
                  padding: 0;
                  padding-left: 5px;
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }
    & .search-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 25px;
      & .search-filter-container {
        display: flex;

        & .search-comp {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-right: 20px;
          & .search-filter-title {
            margin-right: 10px;
          }
        }
        & .search-date {
          & .date-component {
            display: flex;
            align-items: center;
            & .range-text {
              margin: 0 10px;
            }
            & .MuiInputBase-root {
              width: 150px;
              height: 32px;
              & MuiSelect-select {
                width: 150px;
                height: 32px;
              }
              & .MuiInputBase-input {
                padding: 0;
                padding-left: 8px;
              }
            }
          }
        }
        & .search-select-gubn {
          & .MuiInputBase-root {
            width: 120px;
            height: 32px;
            & .MuiSelect-select {
              padding: 0;
              padding-left: 5px;
              padding-right: 15px;
            }
          }
        }
        & .search-input-box {
          & .MuiAutocomplete-root {
            & .MuiFormControl-root {
              & .MuiInputBase-root {
                padding: 0;
              }
            }
          }
          & .MuiFormControl-root {
            min-width: 80px;
            & .MuiInputBase-root {
              width: 250px;
              height: 32px;
            }
          }
        }
      }
    }
    & .detail-table-container {
      & .detail-head-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        & .button-container {
          & .excel-btn {
            margin-left: 5px;
          }
          & button {
            width: 150px;
            height: 32px;
            border: 1px solid #3091ff;
            border-radius: 20px;
            font-size: 16px;
            cursor: pointer;
          }
          & button:hover {
            background-color: #3091ff;
            color: white;
            transition: 0.5s;
          }
        }
      }
    }
  }
}
