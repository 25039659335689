#store-search-return-in {
  .search-container {
    display: flex;
    margin-bottom: 10px;
    & ul {
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      & li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        & .title-box {
          margin-right: 10px;
          padding-bottom: 5px;
        }
      }
      & li:nth-child(1) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        & .first-list-box {
          display: flex;
          align-items: center;
          & .period {
            width: 145px;
            display: flex;
            margin-right: 10px;
            & div {
              display: flex;
              align-items: center;
            }
            & .range-text {
              margin: 0 10px;
            }
            & .MuiFormControl-root {
              & .MuiInputBase-root {
                width: 100%;
                height: 32px;
                padding-left: 13px;
                padding-bottom: 2px;
                & .MuiInputBase-input {
                  position: relative;
                  padding: 0;
                }
              }
            }
          }
          & .search-button-box {
            & button {
              width: 70px;
              font-size: 16px;
              color: #333;
              line-height: 20px;
              height: 32px;
              border: 1px solid black;
              border-radius: 20px;
            }
            & button:hover {
              color: white;
              background-color: #333;
              transition: 0.5s;
            }
          }
        }

        & .delete-button-box {
          & button {
            width: 70px;
            font-size: 16px;
            color: #333;
            line-height: 20px;
            height: 32px;
            border: 1px solid #ff1a1a;
            border-radius: 10px;
          }
          & button:hover {
            color: white;
            border: 1px solid #ff1a1a;
            background-color: #ff1a1a;
            transition: 0.5s;
          }
        }
      }
    }
  }
  & .table-container {
    & .MuiPaper-root {
      box-shadow: none !important;
      & .MuiTableContainer-root {
        & .MuiBox-root {
          box-shadow: none;
          height: 100%;
          display: flex;
          align-items: center;
        }
        & table thead tr th.MuiTableCell-root {
          border-top: 2px #00182e solid;
          background-color: #fff;
          border-bottom: 1px solid #888888;
          box-shadow: none;
        }
      }
      & .MuiInputBase-root {
        border: 1px solid black;
        border-radius: 3px;
        background-color: white;
        padding-left: 5px;
      }
      & .MuiTableHead-root {
        & .MuiTableRow-root {
          & th:nth-of-type(1) {
            max-width: 10px !important;
            & span {
              margin: auto;
            }
          }
        }
      }
      & .MuiTableBody-root {
        & .MuiTableRow-root {
          & td:nth-of-type(1) {
            max-width: 10px !important;
            & span {
              margin: auto;
            }
          }
        }
      }
    }
  }
  & .second-table-title-box {
    margin-top: 25px;
  }
}
