#modoo-faq {
  & .modoo-faq-wrapper {
    & .page-content-box {
      & .search-container {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 10px;
        & .search-box {
          display: flex;
          height: 32px;
          align-items: center;
          & .search-title {
            margin-right: 20px;
            line-height: 32px;
            font-size: 14px;
            font-weight: 600;
          }

          & .search-button {
            width: 75px;
            font-size: 16px;
            color: #333;
            line-height: 20px;
            height: 28px;
            border: 1px solid black;
            border-radius: 20px;
          }
          & .search-button:hover {
            background-color: black;
            color: white;
            transition: 0.7s;
          }
        }
        & .insert-button-container {
          // width: 100%;
          & .insert-button {
            width: 180px;
            height: 35px;
            border: 1px solid #3091ff;
            border-radius: 20px;
            color: black;
            font-size: 16px;
            text-align: center;
          }
          & .insert-button:hover {
            background-color: #3091ff;
            color: white;
            transition: 0.5s;
          }
        }
      }
      & .content-table-container {
        & .MuiPaper-root {
          box-shadow: none !important;

          & .MuiTableContainer-root {
            & .MuiBox-root {
              box-shadow: none;
            }
            & table thead tr th.MuiTableCell-root {
              border-top: 2px #00182e solid;
              background-color: #fff;
              border-bottom: 1px solid #888888;
              box-shadow: none;
            }
            & .MuiTableHead-root {
              & .MuiTableRow-root {
                & th:nth-child(1) {
                  width: 5px;
                }
                & th:nth-child(6) {
                  width: 5px;
                }
              }
            }
            & .MuiTableBody-root {
              & .MuiTableRow-root {
                & td:nth-child(1) {
                  width: 5px;
                }
                & td:nth-child(6) {
                  width: 5px;
                }
              }
            }
            & tfoot {
              background-color: #f7f7f7;
            }
            & .modify-btn {
              height: 22px;
              border: 1px #111 solid;
              background: #fff;
              border-radius: 10px;
              color: #111;
            }
            & .modify-btn:hover {
              background-color: black;
              color: white;
              transition: 0.7s;
            }
          }
          & .MuiInputBase-root {
            border: 1px solid black;
            border-radius: 3px;
            background-color: white;
            padding-left: 5px;
          }
        }
      }
      & .faq-contents {
        width: 100%;
        padding: 0 26px;
        margin: 40px 0 0 0;

        & .faq-box {
          position: relative;
          width: 100%;
          min-height: 32px;
          margin: 0 0 10px 0;
          padding: 0 0 0 84px;
          & .faq-title {
            position: absolute;
            left: 0;
            top: 0;
            & .faq-type {
              font-size: 14px;
              color: #333;
              line-height: 1.2;
            }
          }
          & .first-title {
            top: 0;
          }
          & .faq-data {
            position: relative;
            font-size: 0;
            line-height: 0;

            & .faq-select {
              width: 250px;
              height: 32px;
              float: left;
              text-align: left;
            }
            & .faq-select-readOnly {
              width: 250px;
              height: 32px;
              float: left;
              text-align: left;
              color: rgb(117, 117, 117);
              background-color: #eee;
            }
            & .faq-textfield {
              width: 100%;
            }
            & .faq-text {
              width: 100%;
              height: 120px;
              line-height: 1.4;
              padding: 12px;
              font-size: 12px;
              border: 1px #d2d2d2 solid;
              border-radius: 8px;
              margin: 0;
              box-sizing: border-box;
              vertical-align: middle;
              resize: none;
            }
          }
        }
        & .faq-btn-box {
          padding: 20px 0 5px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          & .reg-btn {
            margin-right: 10px;
            border-radius: 50px;
            width: 84px;
            height: 30px;
            border: 1px #232323 solid;
            color: #232323;
            background: #fff;
            text-align: center;
          }
          & .reg-btn:hover {
            background-color: black;
            color: white;
            transition: 0.7s;
          }
          & .clear-btn {
            width: 84px;
            height: 30px;
            background: #fff;
            text-align: center;
            border-radius: 50px;
            border: 1px #232323 solid;
            color: #232323;
          }
          & .clear-btn:hover {
            background-color: black;
            color: white;
            transition: 0.7s;
          }
        }
      }
    }
    & .select-options {
      height: 32px;
      width: 250px;
      text-align: left;
      margin-right: 10px;
    }

    & .textfield-options {
      width: 100%;
    }
  }
}
