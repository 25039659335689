#mall-news-register {
  & .body-container {
    & .content-wrapper {
      & .mall-news-register-wrapper {
        & .page-contents {
          & .roundBx {
            & .registList {
              & .reBx {
                & .ab {
                  top: 0px;
                }
                & .data {
                  text-align: left;
                  & .period {
                    & .date-picker {
                      & .MuiInputBase-root {
                        max-height: 32px;
                        max-width: 150px;
                        & input:disabled {
                          background-color: transparent;
                        }
                      }
                    }
                  }
                  & textarea {
                    resize: vertical;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
