#modoo-order-status {
  & .modoo-order-status-wrapper {
    & .content-header-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      padding-bottom: 20px;
      border-bottom: 1px #d2d2d2 solid;
      & .search-filter-container {
        width: 1000px;
        // 공용
        & .search-filter {
          display: flex;
          margin-right: 30px;
          & .search-filter-title {
            min-width: 52px;
            margin-right: 15px;
            line-height: 32px;
            font-size: 14px;
            font-weight: 600;
            text-align: left;
          }
          & .search-filter-component {
            display: flex;
            & .date-picker.range {
              display: flex;
              & .date-picker {
                width: 145px;
              }
              & .range-text {
                width: 25px;
                line-height: 32px;
              }
            }
            & .input-field {
              height: 32px;
              width: 250px;
            }
            & input {
              height: 32px;
              line-height: 32px;
              padding-top: 0px;
              padding-bottom: 0px;
            }
            & .MuiOutlinedInput-root {
              font-size: 14px;
            }
          }
        }
        // 입력 그룹
        & .input-container {
          display: flex;
          margin-bottom: 5px;
          & .select-options {
            width: 130px;
            height: 32px;
            margin-right: 10px;
            text-align: left;
          }
        }
        // 체크박스 그룹
        & .check-box-container {
          & .search-filter-title {
            margin-right: 15px;
            font-size: 14px;
            font-weight: 600;
          }
          & .search-filter-component {
            & .MuiFormControlLabel-root {
              height: 32px;
              & .MuiFormControlLabel-label {
                font-size: 14px;
              }
              & .MuiSvgIcon-root {
                font-size: 20px;
              }
            }
          }
        }
      }
      // 버튼영역
      & .button-container {
        display: flex;
        align-items: end;
        & .search-button {
          width: 92px;
          height: 35px;
          background: #000;
          text-align: center;
          border-radius: 50px;
          box-shadow: none;
          span {
            font-size: 16px;
            color: #fff;
            line-height: 35px;
          }
        }
      }
    }

    // 테이블 영역
    & .content-table-container {
      .MuiPaper-root {
        box-shadow: none !important;
        & .MuiTableContainer-root {
          & .MuiBox-root {
            box-shadow: none;
          }
          & table thead tr th.MuiTableCell-root {
            border-top: 2px #00182e solid;
            border-bottom: 1px solid #707070;
            background: #f9f9f9;
          }
        }
        & .MuiInputBase-root {
          border: 1px solid black;
          border-radius: 3px;
          background-color: white;
          padding-left: 5px;
        }

        & .MuiTableCell-body {
          font-weight: 500;
          .swBtn {
            width: auto;
            height: 22px;
            color: #333;
            background: #fff;
            text-align: center;
            font-size: 12px;
            border: 1px #111 solid;
            border-radius: 10px;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }
          .swBtn:hover {
            color: #fff;
            background: #111;
          }
        }
      }
    }
  }
}
