#mall-setting {
  & .mall-setting-wrapper {
    & .page-contents {
      & .page-content-box {
        & .registList {
          & .reBx {
            & .ab {
              top: 0px;
            }
            & .ess::after {
              top: 8px;
            }
            & .data {
              & textarea {
                resize: vertical;
              }
              & .hashDx {
                justify-content: left;
                & .item {
                  margin-right: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
}
