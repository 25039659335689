#home-notice-register {
  & .roundBx {
    margin-bottom: 0px;
  }

  & .addTxt {
    text-align: left;
  }

  & .ab {
    top: 5px;
    font-size: 14px;
    font-weight: 600;
  }

  // 첨부파일
  & .attached-file-list {
    display: flex;
    height: 35px;
    text-align: left;
    margin-bottom: 5px;
    & .attached-file-one {
      display: flex;
      vertical-align: middle;
      max-width: 260px;
      height: 35px;
      margin-right: 20px;
      & .file-name {
        max-width: 220px;
        height: 35px;
        line-height: 30px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 5px;
      }
      & .file-delete-button {
        display: flex;
        width: 25px;
        height: 25px;
        margin: 5px;
        padding: 2px;
        border: 1px solid #ff1a1a;
        color: #ff1a1a;
        border-radius: 5px;
        justify-content: center;
        vertical-align: middle;
        & span {
          display: inline-block;
          width: 18px;
          height: 18px;
        }
        :after {
          display: inline-block;
          content: "\00d7";
          width: 18px;
          height: 18px;
          line-height: 18px;
          font-size: 15px;
          font-weight: 600;
        }
        &:hover {
          color: #fff;
          background: #ff5151;
          border: 1px solid #ff5151;
          transition: 0.5s;
        }
      }
    }
  }

  & .toastui-editor-defaultUI-toolbar {
    & .switch {
      width: 25px !important;
      height: 10px !important;
    }
  }
  & .toastui-editor-contents {
    text-align: left;
  }

  & .fileBx {
    margin-bottom: 5px;
    padding-right: 0;
    display: flex;
    & input {
      height: 35px;
      line-height: 35px;
    }
    & .uploadBtn {
      width: 100px;
      height: 35px;
      position: relative;
    }
    & .button-area {
      margin-left: 15px;
      display: flex;
      & .add-button {
        width: 35px;
        height: 35px;
        padding: 0;
        font-size: 20px;
        line-height: 35px;
        margin-right: 5px;
        border: 1px solid #3091ff;
        color: #3091ff;
        &:hover {
          color: #fff;
          background: #3091ff;
          border: 1px solid #3091ff;
          transition: 0.5s;
        }
      }
      & .del-button {
        width: 35px;
        height: 35px;
        padding: 0;
        font-size: 20px;
        line-height: 35px;
        border: 1px solid #ff1a1a;
        color: #ff1a1a;
        &:hover {
          color: #fff;
          background: #ff5151;
          border: 1px solid #ff5151;
          transition: 0.5s;
        }
      }
    }
  }

  & .input-content-container {
    display: flex;
    height: 35px;
    margin-bottom: 10px;
    & .input-content {
      display: flex;
      margin-right: 30px;
      & .input-content-title {
        min-width: 54px;
        margin-right: 30px;
        line-height: 35px;
        text-align: left;
        font-size: 14px;
        font-weight: 600;
      }
      & .input-content-component {
        display: flex;
        & .input-field {
          width: 1442px;
          height: 35px;
          line-height: 35px;
        }
        & input {
          height: 35px;
          line-height: 35px;
          padding-top: 0px;
          padding-bottom: 0px;
        }
        & .select-options {
          text-align: left;
          width: 120px;
        }
      }
    }
  }

  // 하단 버튼 영역
  & .btnCArea {
    & .submit-button {
      width: 84px;
      height: 30px;
      font-size: 14px;
      color: #fff;
      font-weight: 600;
      line-height: 30px;
      background: #232323;
      text-align: center;
      border-radius: 50px;
      border: 1px #232323 solid;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    & .cancel-button {
      width: 84px;
      height: 30px;
      font-size: 14px;
      color: #232323;
      font-weight: 600;
      line-height: 30px;
      background: #fff;
      text-align: center;
      border-radius: 50px;
      border: 1px #232323 solid;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }
}
