#book-recommended-institutions-register {
  & .wttNode {
    text-align: left;
  }
  & .red-star {
    color: red;
  }
  & .dotNone {
    list-style: none;
    & .MuiFormControl-root {
      min-width: 130px;
      height: 32px;
      & .MuiInputBase-root {
        min-width: 130px;
        height: 32px;
        & .MuiInputBase-root {
          min-width: 80px;
          height: 32px;
        }
      }
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: black;
  }
  & .excel-name {
    width: 380px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    padding: 0, 5px, 0, 5px;
  }
  & .MuiInputBase-root {
    width: 120px;
  }
  & .tt {
    padding-right: 20px;
  }
  & .wfix {
    text-align: left;
  }
  & .excel-upload-btn {
    width: 120px;
    margin: 0;
  }
  & .whiteBtn {
    width: 490px;
    opacity: 0;
  }
  & .btn-li {
    width: 100px;
  }
  & .whiteBtnSm {
    min-width: 100px;
    background: #fff;
    text-align: center;
    min-height: 32px;
    margin: 0;
    padding: 0;
    //margin-left: 25px;
    border-radius: 50px;
    border: 1px #232323 solid;
  }
  & .fAddTxt {
    margin-top: 10px;
    padding: 0;
  }
  & .tbList {
    margin-top: 30px;
  }
}
